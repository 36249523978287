<template>
  <span>
    <div class="kanban-board">
      <div
        class="kanban-stages"
        v-for="(stage, i) in tasksByListStatus"
        :key="stage.id"
        :style="{
          backgroundColor: `#f2f2f2`,
          borderRadius: 5,
        }"
      >
        <div
          class="d-flex white--text white status-header"
          :style="{
            backgroundColor: `${stage.color} !important`,
            borderRadius: '8px 8px 0 0',
            height: `44px`,
          }"
          v-on:dblclick="setStatusText(stage.id, i)"
        >
          <div
            style="
              display: flex;
              align-items: center;
              justify-content: space-between;
              width: 100%;
              padding: 15px;
            "
          >
            <!-- <v-text-field
              @change="updateStatusName($event, i)"
              dense
              v-if="editStatusName[i]"
            ></v-text-field> -->
            <span
              class="d-flex text-uppercase"
              :style="{
                color: `${isDark(stage.color) ? 'white' : 'black'} !important`,
                fontWeight: 500,
              }"
              >{{ stage.name }}
              <div
                class="ml-3 badge badge-default"
                :style="{
                  color: `${
                    !isDark(stage.color) ? 'white' : 'black'
                  } !important`,
                  backgroundColor: `${
                    isDark(stage.color) ? 'white' : 'black'
                  } !important`,
                }"
                style="border-color: transparent !important"
              >
                {{ stage.tasks?.length }}
              </div>
            </span>

            <v-btn
              x-small
              fab
              :color="`${isDark(stage.color) ? 'white' : 'black'} !important`"
              text
              @click.stop="() => $emit('openAddEditTaskDialog', null, stage.id)"
            >
              <v-icon size="25px"> mdi-plus </v-icon>
            </v-btn>
          </div>
        </div>
        <draggable
          v-model="stage.tasks"
          class="drag-area"
          group="people"
          :data-id="stage.id"
          :data-name="stage.name"
          @end="onEnd"
          :move="checkMove"
        >
          <div
            v-for="task in stage.tasks"
            :key="task.task_id"
            class="px-2 my-3"
            :data-id="task.task_id"
          >
            <v-hover v-slot="{ hover }">
              <v-card
                :elevation="hover ? 2 : 0"
                :class="{ 'on-hover': hover }"
                class="ma-0 task-card pa-3"
                style="border: 2px solid #f2f2f2; border-radius: 8px"
              >
                <div>
                  <div class="row">
                    <div
                      class="col-12 text-left drag-item-title cursor-pointer"
                      @click.stop="
                        () =>
                          $emit(
                            'openTaskDetailsDialog',
                            task.task_id,
                            task.list_id
                          )
                      "
                    >
                      {{ task.name }}
                    </div>
                  </div>
                  <div class="row ma-0" v-if="task.task_tags != null">
                    <div
                      v-for="taskTag in task.task_tags"
                      :key="taskTag.id"
                      class="ml-2"
                    >
                      <span
                        class="d-flex align-center"
                        :style="{
                          backgroundColor: taskTag.projectTags.color,

                          padding: '1px 6px',
                          borderRadius: '5px',
                          fontWeight: '500',
                          fontSize: '12px',
                        }"
                      >
                        <span
                          :style="{
                            color: `${
                              isDark(taskTag.projectTags.color)
                                ? 'white'
                                : 'black'
                            } !important`,
                          }"
                        >
                          {{ taskTag.projectTags.name }}
                        </span>
                        <v-btn
                          icon
                          width="14px"
                          height="14px"
                          class="ml-3"
                          style="background-color: white"
                          @click="
                            removeTaskTags(taskTag.id, task.task_id, stage.id)
                          "
                        >
                          <v-icon style="font-size: 14px">mdi-close</v-icon>
                        </v-btn>
                      </span>
                    </div>
                  </div>
                  <div class="row ma-0 pa-0 task-assignees-list mt-2">
                    <div class="d-flex">
                      <template v-for="(assignee, i) in task.assignees">
                        <div
                          class="team-name-avatars"
                          :key="assignee.id"
                          v-if="i < 4"
                        >
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <div
                                class="asssignee-container mr-1"
                                v-if="assignee.employee"
                              >
                                <v-avatar
                                  :color="assignee.employee.color"
                                  class="white--text avatar-icon cursor-pointer mt-2"
                                  size="32"
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  <img
                                    v-if="assignee.employee.profile_url"
                                    :src="assignee.employee.profile_url"
                                    width="22"
                                  />
                                  <span v-else class="fontsize-11">{{
                                    assignee.employee.intials
                                  }}</span>
                                </v-avatar>

                                <v-icon
                                  x-small
                                  dark
                                  @click.stop="
                                    removeAssignee(
                                      assignee.employee.id,
                                      task.task_id,
                                      stage.id
                                    )
                                  "
                                  class="red remove-icon rounded-pill"
                                >
                                  mdi-close
                                </v-icon>
                              </div>
                            </template>
                            <span>{{
                              assignee.employee ? assignee.employee.name : ""
                            }}</span>
                          </v-tooltip>
                        </div>
                        <div
                          class="team-name-avatars"
                          :key="assignee.id"
                          v-else-if="i == 4"
                        >
                          <v-menu
                            offset-y
                            open-on-hover
                            transition="scale-transition"
                            origin="top right"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <div
                                class="asssignee-container mr-1"
                                v-bind="attrs"
                                v-on="on"
                              >
                                <v-avatar
                                  color="primary"
                                  class="white--text avatar-icon cursor-pointer mt-2"
                                  size="32"
                                >
                                  <span class="fontsize-11"
                                    >+{{ task.assignees?.length - 4 }}</span
                                  >
                                </v-avatar>
                              </div>
                            </template>
                            <ul class="more-assignees-menu">
                              <template
                                v-for="(moreAssignee, j) in task.assignees"
                              >
                                <li v-if="j > 3" :key="j">
                                  <div class="asssignee-container mr-1">
                                    <v-avatar
                                      color="primary"
                                      class="white--text avatar-icon cursor-pointer"
                                      size="30"
                                    >
                                      <img
                                        v-if="moreAssignee.employee.profile_url"
                                        :src="moreAssignee.employee.profile_url"
                                        width="22"
                                      />
                                      <span v-else class="fontsize-11">{{
                                        moreAssignee.employee.intials
                                      }}</span>
                                    </v-avatar>
                                  </div>
                                  <span>{{ moreAssignee.employee.name }}</span>
                                  <v-icon
                                    x-small
                                    dark
                                    @click.stop="
                                      removeAssignee(
                                        assignee.employee.id,
                                        task.task_id,
                                        stage.id
                                      )
                                    "
                                    class="red more-remove-icon"
                                  >
                                    mdi-close
                                  </v-icon>
                                </li>
                              </template>
                            </ul>
                          </v-menu>
                        </div>
                      </template>
                    </div>
                    <v-menu offset-y min-width="250px">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          color="grey"
                          dark
                          outlined
                          icon
                          small
                          v-bind="attrs"
                          v-on="on"
                          class="add-assignes"
                          style="margin-left: 15px"
                          @click="filterMember(task, true)"
                        >
                          <v-img
                            src="../../../assets/icons/AddAssigneeIcon.svg"
                            max-width="32"
                            max-height="32"
                            width="32"
                            height="32"
                          />
                        </v-btn>
                      </template>
                      <div class="white">
                        <v-text-field
                          @click.native.stop
                          v-model="taskAssigneeSearch"
                          prepend-inner-icon="mdi-magnify"
                          class="py-1 white"
                          solo
                          flat
                          @input="debouncedtaskAssignee(task)"
                          placeholder="Search..."
                          hide-details=""
                          dense
                        ></v-text-field>
                        <v-divider class="#878DAB" />
                        <v-list>
                          <v-list-item
                            v-for="member in filteredMembers"
                            :key="member.id"
                          >
                            <v-list-item-avatar>
                              <Avatar
                                size="30px"
                                :color="member.employee?.color"
                                :intials="member.employee?.intials"
                                :profile_url="member.employee?.profile_url"
                              />
                            </v-list-item-avatar>
                            <v-list-item-content>
                              <v-list-item-title
                                class="cursor-pointer"
                                @click="
                                  saveAssignee(
                                    member.employee.id,
                                    task.task_id,
                                    stage.id
                                  )
                                "
                              >
                                {{ member.employee.name }}
                              </v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                          <v-list-item v-if="filteredMembers?.length == 0">
                            No Assignees
                          </v-list-item>
                        </v-list>
                      </div>
                    </v-menu>
                  </div>
                  <v-sheet
                    v-if="task.due_date"
                    class="d-flex mt-5 mb-1 align-center"
                    style="border-radius: 6px; height: 44px"
                    color="#F0F4F8"
                  >
                    <b class="ml-3 mr-1">Due Date : </b>
                    {{ formatDate(task.due_date) }}
                  </v-sheet>
                  <div class="row ma-0 px-2 mt-3 align-center">
                    <div class="row align-center">
                      <PrioritySelector
                        @setPriority="
                          (priorityId) =>
                            setTaskPriority(priorityId, task.task_id, stage.id)
                        "
                        :priorityId="task.task_priority || null"
                        :loading="updatingPrioritiesForTaskIds[task.task_id]"
                      />
                      <div
                        class="border-1 border-radius-6 d-flex align-center"
                        style="max-height: 30px"
                      >
                        <v-btn
                          class="elevation-0 px-1"
                          text
                          color="white"
                          small
                          @click="hideShowSubTasks(task.task_id)"
                        >
                          <img
                            src="@/assets/icons/SubTaskIcon.svg"
                            height="14px"
                            width="14px"
                            class="mr-1"
                          />
                          <span
                            class="text--secondary"
                            v-if="task.sub_tasks && task.sub_tasks?.length > 0"
                            >{{ task.sub_tasks?.length }}</span
                          >
                        </v-btn>
                        <v-btn
                          icon
                          depressed
                          small
                          @click="setSubtaskDetails(task.task_id, stage.id)"
                        >
                          <v-icon
                            color="secondary"
                            size="16px"
                            class="pa-1"
                            style="border-left: 1px solid var(--border-color)"
                          >
                            mdi-plus
                          </v-icon>
                        </v-btn>
                      </div>

                      <div
                        v-if="showSubTasks[task.task_id]"
                        style="width: -webkit-fill-available"
                      >
                        <v-card
                          block
                          class="px-4 py-2 my-3"
                          elevation="1"
                          v-for="(subTask, i) in task.sub_tasks"
                          :key="i"
                          @click.stop="
                            () =>
                              $emit(
                                'openTaskDetailsDialog',
                                subTask.task_id,
                                subTask.list_id
                              )
                          "
                        >
                          <div class="row">
                            <div class="col-12">
                              <span>{{ subTask.name }}</span>
                            </div>
                            <div class="col-12 pt-0 d-flex align-center">
                              <div class="d-flex">
                                <template
                                  v-for="(assignee, i) in subTask.assignees"
                                >
                                  <div
                                    class="team-name-avatars"
                                    :key="assignee.id"
                                    v-if="i < 4"
                                  >
                                    <v-tooltip bottom>
                                      <template
                                        v-slot:activator="{ on, attrs }"
                                      >
                                        <div
                                          class="asssignee-container mr-1"
                                          v-if="assignee.employee"
                                        >
                                          <v-avatar
                                            :color="assignee.employee.color"
                                            class="white--text avatar-icon cursor-pointer mt-0"
                                            size="32"
                                            v-bind="attrs"
                                            v-on="on"
                                          >
                                            <img
                                              v-if="
                                                assignee.employee.profile_url
                                              "
                                              :src="
                                                assignee.employee.profile_url
                                              "
                                              width="22"
                                            />
                                            <span v-else class="fontsize-11">{{
                                              assignee.employee.intials
                                            }}</span>
                                          </v-avatar>

                                          <v-icon
                                            x-small
                                            dark
                                            @click.stop="
                                              removeSubTaskAssignee(
                                                assignee.employee.id,
                                                task.task_id,
                                                subTask.task_id,
                                                stage.id
                                              )
                                            "
                                            class="red remove-icon rounded-pill"
                                          >
                                            mdi-close
                                          </v-icon>
                                        </div>
                                      </template>
                                      <span>{{
                                        assignee.employee
                                          ? assignee.employee.name
                                          : ""
                                      }}</span>
                                    </v-tooltip>
                                  </div>
                                  <div
                                    class="team-name-avatars"
                                    :key="assignee.id"
                                    v-else-if="i == 4"
                                  >
                                    <v-menu
                                      offset-y
                                      open-on-hover
                                      transition="scale-transition"
                                      origin="top right"
                                    >
                                      <template
                                        v-slot:activator="{ on, attrs }"
                                      >
                                        <div
                                          class="asssignee-container mr-1"
                                          v-bind="attrs"
                                          v-on="on"
                                        >
                                          <v-avatar
                                            color="primary"
                                            class="white--text avatar-icon cursor-pointer mt-2"
                                            size="32"
                                          >
                                            <span class="fontsize-11"
                                              >+{{
                                                subTask.assignees?.length - 4
                                              }}</span
                                            >
                                          </v-avatar>
                                        </div>
                                      </template>
                                      <ul class="more-assignees-menu">
                                        <template
                                          v-for="(
                                            moreAssignee, j
                                          ) in subTask.assignees"
                                        >
                                          <li v-if="j > 3" :key="j">
                                            <div
                                              class="asssignee-container mr-1"
                                            >
                                              <v-avatar
                                                color="primary"
                                                class="white--text avatar-icon cursor-pointer"
                                                size="30"
                                              >
                                                <img
                                                  v-if="
                                                    moreAssignee.employee
                                                      .profile_url
                                                  "
                                                  :src="
                                                    moreAssignee.employee
                                                      .profile_url
                                                  "
                                                  width="22"
                                                />
                                                <span
                                                  v-else
                                                  class="fontsize-11"
                                                  >{{
                                                    moreAssignee.employee
                                                      .intials
                                                  }}</span
                                                >
                                              </v-avatar>
                                            </div>
                                            <span>{{
                                              moreAssignee.employee.name
                                            }}</span>
                                            <v-icon
                                              x-small
                                              dark
                                              @click.stop="
                                                removeSubTaskAssignee(
                                                  assignee.employee.id,
                                                  task.task_id,
                                                  subTask.task_id,
                                                  stage.id
                                                )
                                              "
                                              class="red more-remove-icon"
                                            >
                                              mdi-close
                                            </v-icon>
                                          </li>
                                        </template>
                                      </ul>
                                    </v-menu>
                                  </div>
                                </template>
                              </div>
                              <v-menu offset-y min-width="250px">
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                    color="grey"
                                    dark
                                    outlined
                                    icon
                                    small
                                    v-bind="attrs"
                                    v-on="on"
                                    class="add-assignes mt-0"
                                    style="margin-left: 15px; margin-top: 2px"
                                    @click="filterSubTaskMember(subTask, true)"
                                  >
                                    <v-img
                                      src="../../../assets/icons/AddAssigneeIcon.svg"
                                      max-width="32"
                                      max-height="32"
                                      width="32"
                                      height="32"
                                    />
                                  </v-btn>
                                </template>
                                <div class="white">
                                  <v-text-field
                                    @click.native.stop
                                    v-model="subTaskAssigneeSearch"
                                    prepend-inner-icon="mdi-magnify"
                                    class="py-1 white"
                                    solo
                                    flat
                                    @input="debouncedSubTaskAssignee(subTask)"
                                    placeholder="Search..."
                                    hide-details=""
                                    dense
                                  ></v-text-field>
                                  <v-divider class="#878DAB" />
                                  <v-list>
                                    <v-list-item
                                      v-for="member in filterSubTaskMembers"
                                      :key="member.id"
                                    >
                                      <v-list-item-avatar>
                                        <!-- <v-img :src="member.employee.profile_url" width="30px"></v-img> -->
                                        <Avatar
                                          size="30px"
                                          :color="member.employee?.color"
                                          :intials="member.employee?.intials"
                                          :profile_url="
                                            member.employee?.profile_url
                                          "
                                        />
                                      </v-list-item-avatar>
                                      <v-list-item-content>
                                        <v-list-item-title
                                          class="cursor-pointer"
                                          @click="
                                            saveSubTaskAssignee(
                                              member.employee.id,
                                              task.task_id,
                                              subTask.task_id,
                                              stage.id
                                            )
                                          "
                                        >
                                          {{ member.employee.name }}
                                        </v-list-item-title>
                                      </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item
                                      v-if="filterSubTaskMembers?.length == 0"
                                    >
                                      No Assignees
                                    </v-list-item>
                                  </v-list>
                                </div>
                              </v-menu>
                            </div>
                          </div>
                        </v-card>
                      </div>
                    </div>
                    <v-spacer></v-spacer>

                    <div class="d-flex align-center">
                      <v-menu offset-y min-width="250px">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn icon v-bind="attrs" v-on="on">
                            <v-icon>mdi-tag-multiple-outline</v-icon>
                          </v-btn>
                        </template>
                        <div class="white">
                          <v-text-field
                            v-model="newTag.name"
                            @click.native.stop
                            dense
                            hide-details
                            solo
                            flat
                            placeholder="Add New Tag"
                            class="subtitle-2 py-1"
                          >
                            <template v-slot:prepend-inner>
                              <v-menu
                                top
                                nudge-bottom="300"
                                nudge-left="16"
                                :close-on-content-click="false"
                              >
                                <template v-slot:activator="{ on }">
                                  <div :style="swatchStyle" v-on="on"></div>
                                </template>
                                <v-card>
                                  <v-card-text class="pa-0">
                                    <v-color-picker
                                      v-model="newTag.color"
                                      flat
                                    />
                                  </v-card-text>
                                </v-card>
                              </v-menu>
                            </template>
                            <template v-slot:append>
                              <v-btn
                                @click="storeNewTag()"
                                color="primary"
                                x-small
                                :style="{
                                  marginTop: '4px',
                                  backgroundColor: 'white',
                                }"
                              >
                                save
                              </v-btn>
                            </template>
                          </v-text-field>
                          <v-divider class="#878DAB" />
                          <v-list>
                            <v-list-item
                              v-for="tags in projectTags"
                              :key="tags.id"
                              style="min-height: 30px; max-height: 40px"
                            >
                              <v-list-item-icon style="margin: 8px 16px 8px 0">
                                <v-icon :color="tags.color"
                                  >mdi-tag-multiple</v-icon
                                >
                              </v-list-item-icon>
                              <v-list-item-content
                                @click="
                                  addTaskTag(task.task_id, tags.id, stage.id)
                                "
                              >
                                <v-list-item-title class="cursor-pointer">
                                  {{ tags.name }}
                                </v-list-item-title>
                              </v-list-item-content>
                              <v-list-item-action>
                                <v-btn
                                  x-small
                                  icon
                                  @click="removeProjectTags(tags.id)"
                                >
                                  <v-icon>mdi-close</v-icon>
                                </v-btn>
                              </v-list-item-action>
                            </v-list-item>
                            <v-list-item v-if="projectTags?.length == 0">
                              No Tags
                            </v-list-item>
                          </v-list>
                        </div>
                      </v-menu>
                    </div>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          icon
                          @click.stop="() => $emit('deleteTask', task.task_id)"
                          v-bind="attrs"
                          v-on="on"
                          class="pa-0 ma-0"
                        >
                          <TrashIcon :size="19" />
                        </v-btn>
                      </template>
                      <span>Delete</span>
                    </v-tooltip>
                  </div>
                </div>
              </v-card>
            </v-hover>
          </div>
        </draggable>
      </div>

      <Drawer
        v-model="addSubtaskDialog"
        @close="closeSubtaskDetails"
        :permanent="value"
      >
        <template v-slot:title> Add Sub Task </template>
        <template v-slot:content>
          <v-row>
            <v-col cols="12" class="pb-0">
              <v-text-field
                v-model="subtaskEditedItem.name"
                outlined
                label="Name"
                name="name"
                dense
                :error="subtaskErrors.name != null"
                :error-messages="subtaskErrors.name"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" class="py-0">
              <v-textarea
                v-model="subtaskEditedItem.description"
                rows="3"
                outlined
                label="Description"
                auto-grow
                class="black--text subtitle-1"
                :error="subtaskErrors.description != null"
                :error-messages="subtaskErrors.description"
              >
              </v-textarea>
            </v-col>
            <v-col cols="12" class="pt-0">
              <v-autocomplete
                v-model="subtaskEditedItem.assignees"
                :items="projectTeamMembers"
                item-text="employee.name"
                item-value="employee_id"
                label="Assignee"
                outlined
                dense
                multiple
                :error="subtaskErrors.assignees != null"
                :error-messages="subtaskErrors.assignees"
                chips
                deletable-chips
              >
                <template v-slot:item="data">
                  <template v-if="typeof data.item !== 'object'">
                    <v-list-item-content
                      v-text="data.item"
                    ></v-list-item-content>
                  </template>
                  <template v-else>
                    <v-list-item-avatar
                      style="min-width: 33px; height: 33px; width: 33px"
                    >
                      <Avatar
                        size="30px"
                        :color="data.item.employee?.color"
                        :intials="data.item.employee?.intials"
                        :profile_url="data.item.employee?.profile_url"
                      />
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title
                        v-html="data.item.employee.name"
                      ></v-list-item-title>
                    </v-list-item-content>
                  </template>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="12" class="pt-0">
              <v-btn
                color="#858585"
                small
                text
                class="ml-0 px-0"
                @click="setAttachmentDialog"
              >
                <v-icon>mdi-paperclip</v-icon>
                Attachment
              </v-btn>
            </v-col>
          </v-row>
        </template>
        <template v-slot:footer>
          <v-btn
            class="full-width border-radius-6 py-5"
            depressed
            color="primary"
            @click="saveOrUpdateSubTask"
            :loading="loading"
          >
            Save
          </v-btn>
        </template>
      </Drawer>

      <Upload
        ref="attachmentfile-uploader"
        :dialog.sync="AttachmentDialog"
        :multiple="true"
        @filesUploaded="storeAttachment($event)"
      />

      <v-snackbar
        v-model="error"
        :timeout="3000"
        color="deep-orange accent-4"
        align-baseline="center"
      >
        {{ message }}
        <template v-slot:action="{ attrs }">
          <v-btn text v-bind="attrs" @click="error = false"> Close </v-btn>
        </template>
      </v-snackbar>

      <v-snackbar
        v-model="success"
        :timeout="3000"
        color="green accent-4"
        align-baseline="center"
      >
        {{ message }}
        <template v-slot:action="{ attrs }">
          <v-btn text v-bind="attrs" @click="success = false"> Close </v-btn>
        </template>
      </v-snackbar>

      <div class="grey--text text-center body-2" v-show="showLoadingTasks">
        <v-overlay :value="isLoadingTasks" :opacity="0.02" color="#4483f7">
          <div class="pa-5 border-radius-6 custom-loader">
            <v-progress-circular
              indeterminate
              size="40"
              color="primary"
              class="mx-1"
            ></v-progress-circular>
          </div>
        </v-overlay>
      </div>
      <v-overlay
        absolute
        :value="isErrorOccured"
        color="white"
        opacity="1"
        height="70vh"
      >
        <div class="text-center">
          <p
            class="primary--text"
            style="font-size: 50px; margin: 0px; height: 40px"
          >
            Oops !
          </p>
          <p class="grey--text subtitle-1">{{ errorMessage }}</p>
          <br />
          <v-btn
            color="primary"
            class="mt-3"
            rounded
            depressed
            @click="$router.push(`/ projects / `)"
            ><v-icon size="18" class="mr-3">mdi-arrow-left</v-icon>Go
            Back</v-btn
          >
        </div>
      </v-overlay>
    </div>
  </span>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import draggable from "vuedraggable";
import { debounce } from "lodash";
import PrioritySelector from "../../common/basic/PrioritySelector.vue";
import TrashIcon from "@/assets/icons/TrashIcon.vue";
import Drawer from "@/components/common/basic/Drawer.vue";
import Avatar from "../../common/basic/Avatar.vue";
import Upload from "../../shared/UploadFile.vue";
import moment from "moment";
import { isDark } from "../../../helper/uttils";

export default {
  name: "Board",
  props: {
    list_id: Number,
    project_id: String,
    search: [String, null],
    selectedTeamMembers: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isLoadingTasks: false,
      openedPanels: [],
      cancelTokenSource: null,
      viewSubTask: false,
      subTaskParentId: null,
      updatingPrioritiesForTaskIds: {},
      filteredMembers: [],
      filterSubTaskMembers: [],
      taskAssigneeSearch: "",
      subTaskAssigneeSearch: "",
      dragStatusId: "",
      dragTaskId: "",
      blockTaskId: "",
      taskDetailDialog: false,
      editStatusName: {},
      changeStatusId: "",
      renderIssue: false,
      message: "",
      loading: false,
      isErrorOccured: false,
      errorMessage: "",
      error: false,
      success: false,
      addSubtaskDialog: false,
      parent: Number,
      members: [],
      newStatus: {
        name: null,
        color: "#006EFF",
        folder_id: null,
      },
      subtaskEditedItem: {
        task_id: "",
        name: "",
        description: "",
        assignees: [],
        status: "",
        displayAssignees: [],
        created_at: new Date(),
      },
      subtaskErrors: [],
      selectedSubtaskStage: "",
      subtaskAttachments: [],
      folderId: null,
      showSubTasks: {},
      selectedSubTask: "",
      attachments: [],
      AttachmentDialog: false,
      value: false,
      taskTags: [],
      tasksByListStatus: [],
      newTag: {
        name: null,
        color: "#006EFF",
        project_id: null,
      },
    };
  },
  components: {
    draggable,
    PrioritySelector,
    TrashIcon,
    Drawer,
    Avatar,
    Upload,
  },
  computed: {
    ...mapState("projects", [
      "projectTeamMembers",
      "projectTags",
      "cachesTeamMembers",
    ]),
    showNoListSelected() {
      return !this.list_id && !this.isLoadingTasks;
    },
    swatchStyle() {
      const { menu } = this;
      return {
        backgroundColor: this.newStatus.color,
        cursor: "pointer",
        height: "15px",
        width: "15px",
        borderRadius: menu ? "50%" : "4px",
        transition: "border-radius 200ms ease-in-out",
        marginTop: "7px",
        marginRight: "3px",
      };
    },
    showNoTasks() {
      return (
        this.list_id && !this.tasksByListStatus?.length && !this.isLoadingTasks
      );
    },
    showLoadingTasks() {
      // hiding loader when searching data to avoid flicker
      return !this.search && this.isLoadingTasks;
    },
  },
  watch: {
    // fetch the tasks for new list id
    list_id: function () {
      if (this.list_id) {
        this.getListTasksByStatus();
      }
    },

    search: function () {
      this.getListTasksByStatus();
    },
    selectedTeamMembers: {
      handler: function () {
        if (this.list_id) {
          this.getListTasksByStatus();
        }
      },
      deep: true,
    },
  },
  methods: {
    ...mapMutations("projects", [
      "setProjectTeamMembers",
      "setProjectTags",
      "updateProjectCaches",
    ]),
    isDark(color) {
      return isDark(color);
    },
    setAttachmentDialog() {
      this.attachments = [];
      this.AttachmentDialog = true;
    },
    storeAttachment(files) {
      for (let index = 0; index < files?.length; index++) {
        const file = files[index];
        this.attachments.push(file);
      }

      if (this.attachments?.length > 0) {
        this.$refs["attachmentfile-uploader"].closeDialog();
      }
    },

    showAlertMessage(isSucessMsg, message) {
      this.alert = {
        message: message || "",
        color: isSucessMsg ? "green accent-4" : "red accent-2",
        show: true,
      };
    },
    setStatusDataToObj(stage) {
      this.editStatusName[stage.id] = {
        isEdit: false,
        name: stage.name,
      };
    },
    onEnd(e) {
      this.dragStatusId = e.to.dataset.id;
      this.dragTaskId = e.item.dataset.id;
      if (this.dragStatusId && this.dragTaskId) {
        this.changeTaskStatus(this.dragTaskId, this.dragStatusId);
      }
    },
    checkMove() {
      return true;
    },
    formatDate(date) {
      // Use moment to format the date
      return moment(date, "YYYY-MM-DD").format("MMMM DD, YYYY");
    },
    getListTasksByStatus() {
      this.isLoadingTasks = true;

      // let requestStartTime = new Date().getTime();

      // Cancel any previous requests
      if (this.cancelTokenSource) {
        this.cancelTokenSource.cancel();
      }

      // Create a new CancelToken source
      this.cancelTokenSource = this.$axios.CancelToken.source();

      this.$axios
        .get(
          `/list/${this.list_id}/tasks/by_status?search=${
            this.search || ""
          }&selectedTeamMembers=${this.selectedTeamMembers.join(",")}`,
          {
            cancelToken: this.cancelTokenSource.token,
          }
        )
        .then((response) => {
          const resData = response.data;

          if (resData.status) {
            // only displaying status which have one or more tasks when search term is present
            if (this.search || this.selectedTeamMembers?.length) {
              resData.data.tasksByListStatus =
                resData.data.tasksByListStatus.filter((status) => {
                  return status.tasks && status.tasks?.length;
                });
            }
            this.tasksByListStatus = resData.data.tasksByListStatus;
            this.folderId = resData.data.tasksByListStatus[0]?.folder_id;
            this.isLoadingTasks = false;
            this.openedPanels = Array.from(
              Array(resData.data.tasksByListStatus?.length + 1).keys()
            );
          } else {
            this.isErrorOccured = true;
            this.errorMessage = resData.message;
            this.isLoadingTasks = false;
            // this.showAlertMessage(
            //   false,
            //   resData.message || "Something went wrong"
            // );
          }
        })
        .catch((error) => {
          this.isLoadingTasks = false;
          if (this.$axios.isCancel(error)) {
            // Request canceled
            console.log("Request canceled:");
          } else {
            this.showAlertMessage(false, "Something went wrong");
          }
        });
    },
    hideShowSubTasks(parentId) {
      this.viewSubTask = !this.viewSubTask;
      this.showSubTasks = {
        ...this.showSubTasks,
        [parentId]: !this.showSubTasks[parentId],
      };
    },
    setTaskPriority(priorityId, task_id, statusId) {
      this.updatingPrioritiesForTaskIds = {
        ...this.updatingPrioritiesForTaskIds,
        [task_id]: true,
      };
      this.$axios
        .put(`task_priority/${task_id}`, {
          task_priority: priorityId || "",
        })
        .then((response) => {
          const resData = response.data;

          if (resData.success) {
            let statusIndex = this.tasksByListStatus.findIndex(
              (status) => status.id == statusId
            );
            let statusData = this.tasksByListStatus.filter((status) => {
              if (status.id == statusId) {
                return status;
              }
            });
            let tasks = [];
            tasks = statusData[0].tasks;
            let taskIndex = tasks.findIndex((task) => task.task_id == task_id);

            if (taskIndex != -1) {
              tasks[taskIndex].task_priority = priorityId;
            }

            if (statusIndex != -1) {
              let tempTasksByListStatus = [...this.tasksByListStatus];

              tempTasksByListStatus[statusIndex] = {
                ...tempTasksByListStatus[statusIndex],
                tasks: tasks,
              };

              this.tasksByListStatus = tempTasksByListStatus;
            }
          } else {
            this.showAlertMessage(
              false,
              resData.message || "Something went wrong"
            );
          }
        })
        .catch(() => {
          this.showAlertMessage(false, "Something went wrong");
        })
        .finally(() => {
          this.updatingPrioritiesForTaskIds = {
            ...this.updatingPrioritiesForTaskIds,
            [task_id]: false,
          };
        });
    },
    removeAssignee(employee_id, task_id, statusId) {
      let _self = this;
      _self.$axios
        .post("tasks/" + task_id + "/assignee/remove", {
          employee_id: employee_id,
          project_id: this.project_id,
        })
        .then((response) => {
          let data = response.data;
          _self.loading = false;

          if (data.success) {
            let statusIndex = this.tasksByListStatus.findIndex(
              (status) => status.id == statusId
            );
            let statusData = this.tasksByListStatus.filter((status) => {
              if (status.id == statusId) {
                return status;
              }
            });
            let tasks = [];
            tasks = statusData[0].tasks;
            let taskIndex = tasks.findIndex((task) => task.task_id == task_id);

            if (taskIndex != -1) {
              if (tasks[taskIndex] && tasks[taskIndex].assignees) {
                tasks[taskIndex].assignees = tasks[taskIndex].assignees.filter(
                  (assignee) => assignee.employee.id != employee_id
                );
              }
              if (statusIndex != -1) {
                let tempTasksByListStatus = [...this.tasksByListStatus];

                tempTasksByListStatus[statusIndex] = {
                  ...tempTasksByListStatus[statusIndex],
                  tasks: tasks,
                };

                this.tasksByListStatus = tempTasksByListStatus;
              }
            }
          } else {
            _self.error = true;
            _self.message = data.message;
          }
        });
    },
    removeSubTaskAssignee(employee_id, task_id, subTask_id, statusId) {
      let _self = this;
      _self.$axios
        .post("tasks/" + subTask_id + "/assignee/remove", {
          employee_id: employee_id,
          project_id: this.project_id,
        })
        .then((response) => {
          let data = response.data;
          _self.loading = false;

          if (data.success) {
            let statusIndex = this.tasksByListStatus.findIndex(
              (status) => status.id == statusId
            );
            let statusData = this.tasksByListStatus.filter((status) => {
              if (status.id == statusId) {
                return status;
              }
            });
            let tasks = [];
            tasks = statusData[0].tasks;
            let taskIndex = tasks.findIndex((task) => task.task_id == task_id);

            let taskData = tasks.filter((task) => {
              if (task.task_id == task_id) {
                return task;
              }
            });
            let subtasks = [];
            subtasks = taskData[0].sub_tasks;
            let subtaskIndex = subtasks.findIndex(
              (subtask) => subtask.task_id == subTask_id
            );

            if (taskIndex != -1) {
              if (subtasks[subtaskIndex] && subtasks[subtaskIndex].assignees) {
                subtasks[subtaskIndex].assignees = subtasks[
                  subtaskIndex
                ].assignees.filter(
                  (assignee) => assignee.employee.id != employee_id
                );
              }

              if (tasks[taskIndex] && tasks[taskIndex].assignees) {
                tasks[taskIndex].sub_tasks = subtasks;
              }

              // if (tasks[taskIndex] && tasks[taskIndex].assignees) {
              //   tasks[taskIndex].assignees = tasks[
              //     taskIndex
              //   ].assignees.filter(
              //     (assignee) => assignee.employee.id != employee_id
              //   );
              // }
              if (statusIndex != -1) {
                let tempTasksByListStatus = [...this.tasksByListStatus];

                tempTasksByListStatus[statusIndex] = {
                  ...tempTasksByListStatus[statusIndex],
                  tasks: tasks,
                };

                this.tasksByListStatus = tempTasksByListStatus;
              }
            }
          } else {
            _self.error = true;
            _self.message = data.message;
          }
        });
    },
    filterMember(task, fromTaskList = false) {
      this.taskAssigneeSearch = "";
      this.selectedTaskDetails = task;
      this.selectedTask = task.task_id;
      if (fromTaskList) {
        this.filteredMembers = this.projectTeamMembers?.filter((member) => {
          return !task.assignees.find((assignee) => {
            return assignee.employee.id == member.employee.id;
          });
        });
      } else {
        this.filteredMembers = this.projectTeamMembers?.filter((member) => {
          return !task.displayAssignees.find((assignee) => {
            return assignee.employee.id == member.employee.id;
          });
        });
      }
    },
    filterSubTaskMember(subTask, fromTaskList = false) {
      this.subTaskAssigneeSearch = "";
      // this.selectedTaskDetails = task;
      this.selectedSubTask = subTask.task_id;
      if (fromTaskList) {
        this.filterSubTaskMembers = this.projectTeamMembers.filter((member) => {
          return !subTask.assignees.find((assignee) => {
            return assignee.employee.id == member.employee.id;
          });
        });
      }
    },
    debouncedtaskAssignee: debounce(function (members) {
      const arrayColumn = (arr, n) => arr.map((x) => x[n]);
      let addAssigneelist = arrayColumn(members.assignees, "employee_id");
      this.filteredMembers = this.projectTeamMembers.filter((item) => {
        let empName = item.employee.name.toLocaleLowerCase();
        if (
          empName?.includes(this.taskAssigneeSearch?.toLocaleLowerCase()) &&
          !addAssigneelist.includes(item.employee_id)
        ) {
          return item;
        }
      });
    }, 500),
    debouncedSubTaskAssignee: debounce(function (members) {
      const arrayColumn = (arr, n) => arr.map((x) => x[n]);
      let addAssigneelist = arrayColumn(members.assignees, "employee_id");
      this.filterSubTaskMembers = this.projectTeamMembers.filter((item) => {
        let empName = item.employee.name.toLocaleLowerCase();
        if (
          empName?.includes(this.subTaskAssigneeSearch?.toLocaleLowerCase()) &&
          !addAssigneelist.includes(item.employee_id)
        ) {
          return item;
        }
      });
    }, 500),
    getEmployees() {
      if (this.cachesTeamMembers) {
        return;
      }
      if (!this.project_id) return;

      let _self = this;
      _self.loading = true;
      _self.$axios
        .post("getmembers", {
          project_id: _self.project_id,
        })
        .then((res) => {
          let data = res.data;
          _self.loading = false;

          if (data.success) {
            this.setProjectTeamMembers(data.members);
            this.updateProjectCaches({ key: "cachesTeamMembers", value: true });
          } else {
            _self.snackbarError = true;
            _self.message = data.message;
          }
        });
    },
    changeTaskStatus(task_id, status_id) {
      let _self = this;
      _self.loading = true;
      let dataObj = {
        status: status_id,
        project_id: this.project_id,
      };
      _self.$axios
        .put("tasks/" + task_id + "/changeStatus", dataObj)
        .then((response) => {
          let data = response.data;
          _self.loading = false;
          if (data.success) {
            data;
          } else {
            _self.error = true;
            _self.message = data.message;
          }
        });
    },
    saveAssignee(employee_id, task_id, statusId) {
      let _self = this;

      _self.$axios
        .post("tasks/" + _self.selectedTask + "/assignee", {
          employee_id: employee_id,
          project_id: this.project_id,
        })
        .then((response) => {
          let data = response.data;
          _self.loading = false;

          if (data.success) {
            let statusIndex = this.tasksByListStatus.findIndex(
              (status) => status.id == statusId
            );
            let statusData = this.tasksByListStatus.filter((status) => {
              if (status.id == statusId) {
                return status;
              }
            });
            let tasks = [];
            tasks = statusData[0].tasks;
            let taskIndex = tasks.findIndex((task) => task.task_id == task_id);

            if (taskIndex != -1) {
              if (tasks[taskIndex] && tasks[taskIndex].assignees) {
                tasks[taskIndex].assignees = data.task?.assignees;
              }
              if (statusIndex != -1) {
                let tempTasksByListStatus = [...this.tasksByListStatus];

                tempTasksByListStatus[statusIndex] = {
                  ...tempTasksByListStatus[statusIndex],
                  tasks: tasks,
                };

                this.tasksByListStatus = tempTasksByListStatus;
              }
            }
          } else {
            _self.error = true;
            _self.message = data.message;
          }
        });
    },
    saveSubTaskAssignee(employee_id, task_id, subTask_id, statusId) {
      let _self = this;

      _self.$axios
        .post("tasks/" + _self.selectedSubTask + "/assignee", {
          employee_id: employee_id,
          project_id: this.project_id,
        })
        .then((response) => {
          let data = response.data;
          _self.loading = false;

          if (data.success) {
            let statusIndex = this.tasksByListStatus.findIndex(
              (status) => status.id == statusId
            );
            let statusData = this.tasksByListStatus.filter((status) => {
              if (status.id == statusId) {
                return status;
              }
            });
            let tasks = [];
            tasks = statusData[0].tasks;
            let taskIndex = tasks.findIndex((task) => task.task_id == task_id);
            let taskData = tasks.filter((task) => {
              if (task.task_id == task_id) {
                return task;
              }
            });
            let subtasks = [];
            subtasks = taskData[0].sub_tasks;
            let subtaskIndex = subtasks.findIndex(
              (subtask) => subtask.task_id == subTask_id
            );

            if (taskIndex != -1) {
              if (subtasks[subtaskIndex] && subtasks[subtaskIndex].assignees) {
                subtasks[subtaskIndex].assignees = data.task?.assignees;
              }
              if (tasks[taskIndex] && tasks[taskIndex].assignees) {
                tasks[taskIndex].sub_tasks = subtasks;
              }
              if (statusIndex != -1) {
                let tempTasksByListStatus = [...this.tasksByListStatus];

                tempTasksByListStatus[statusIndex] = {
                  ...tempTasksByListStatus[statusIndex],
                  tasks: tasks,
                };

                this.tasksByListStatus = tempTasksByListStatus;
              }
            }
          } else {
            _self.error = true;
            _self.message = data.message;
          }
        });
    },
    setStatusText(statusId, i) {
      this.changeStatusId = statusId;
      let tmpObj = {};
      tmpObj[i] = true;

      this.editStatusName = { ...this.editStatusName, ...tmpObj };
    },

    storeNewStatus(folderId) {
      this.newStatus.folder_id = folderId;
      this.newStatus.list_id = this.list_id;
      this.$axios
        .post("status/" + folderId, this.newStatus)
        .then((response) => {
          let data = response.data.data;
          if (response.data.success) {
            const updatedData = JSON.parse(
              JSON.stringify(this.tasksByListStatus)
            );
            updatedData.push(data);
            // this.tasksByListStatus.push(data)
            this.tasksByListStatus = updatedData;
            this.$emit("getListStatuses");
            this.newStatus = {
              name: null,
              color: "#006EFF",
              folder_id: null,
            };
          }
        });
    },
    setSubtaskDetails(parentTaskId = "", statusId) {
      this.parent = parentTaskId;
      this.subtaskEditedItem.status = statusId;
      this.subtaskEditedItem.task_id = "";
      this.subtaskEditedItem.name = "";
      this.subtaskEditedItem.description = "";
      this.subtaskEditedItem.assignees = [];
      this.addSubtaskDialog = true;
      this.value = true;
    },
    closeSubtaskDetails() {
      this.value = false;
      this.addSubtaskDialog = false;
      this.subtaskEditedItem.task_id = "";
      this.subtaskEditedItem.name = "";
      this.subtaskEditedItem.description = "";
      this.subtaskEditedItem.assignees = [];
      this.subtaskEditedItem.created_at = new Date();
      this.attachments = [];
      this.subtaskErrors = [];
    },
    saveOrUpdateSubTask() {
      let _self = this;
      _self.loading = true;
      let formData = new FormData();
      formData.append("name", _self.subtaskEditedItem.name);
      formData.append("description", _self.subtaskEditedItem.description);
      formData.append("project_id", _self.project_id);
      formData.append("list_id", _self.list_id);
      formData.append("status", _self.selectedSubtaskStage);
      formData.append("parentTaskId", _self.parent);

      if (_self.subtaskEditedItem.assignees?.length > 0) {
        for (let j = 0; j < _self.subtaskEditedItem.assignees?.length; j++) {
          formData.append("assignees[]", _self.subtaskEditedItem.assignees[j]);
        }
      }

      if (this.attachments) {
        for (let i = 0; i < this.attachments?.length; i++) {
          formData.append(`attachments[${i}]`, this.attachments[i]);
        }
      }
      _self.$axios
        .post("tasks", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          _self.loading = false;
          let data = response.data;
          _self.subtaskErrors = [];
          if (!data.success) {
            if (data.errors) {
              for (let i = 0; i < data.errors?.length; i++) {
                _self.subtaskErrors[data.errors[i].field] =
                  data.errors[i].message;
              }
            } else {
              _self.error = true;
              _self.message = data.message;
            }
          } else {
            _self.success = true;
            _self.message = data.message;

            let statusIndex = this.tasksByListStatus.findIndex(
              (status) => status.id == this.subtaskEditedItem.status
            );
            let statusData = this.tasksByListStatus.filter((status) => {
              if (status.id == this.subtaskEditedItem.status) {
                return status;
              }
            });
            let tasks = [];
            tasks = statusData[0].tasks;
            let taskIndex = tasks.findIndex(
              (task) => task.task_id == this.parent
            );

            if (taskIndex != -1) {
              tasks[taskIndex].sub_tasks.push(data.task);
            }

            if (statusIndex != -1) {
              let tempTasksByListStatus = [...this.tasksByListStatus];

              tempTasksByListStatus[statusIndex] = {
                ...tempTasksByListStatus[statusIndex],
                tasks: tasks,
              };

              this.tasksByListStatus = tempTasksByListStatus;
            }
            _self.closeSubtaskDetails();
          }
        });
    },
    invertColor(hexcolor) {
      // If a leading # is provided, remove it
      if (hexcolor.slice(0, 1) === "#") {
        hexcolor = hexcolor.slice(1);
      }
      // Convert to RGB value
      var r = parseInt(hexcolor.substr(0, 2), 16);
      var g = parseInt(hexcolor.substr(2, 2), 16);
      var b = parseInt(hexcolor.substr(4, 2), 16);
      // Get YIQ ratio
      var yiq = (r * 299 + g * 587 + b * 114) / 1000;
      // Check contrast
      return yiq >= 128 ? "black" : "white";
    },
    storeNewTag() {
      let objdata = {
        name: this.newTag.name,
        color: this.newTag.color,
        project_id: this.project_id,
      };
      let _self = this;
      _self.$axios.post("add_new_tags", objdata).then((response) => {
        let data = response.data;
        if (data.success) {
          this.setProjectTags([...this.projectTags, data.tags]);

          this.loading = false;
          this.newTag.name = "";
          this.newTag.color = "#006EFF";
        } else {
          this.error = true;
          this.message = data.message ? data.message : data.errors[0].message;
          this.loading = false;
        }
      });
    },
    getProjectTags() {
      if (this.projectTags?.length || !this.project_id) {
        return;
      }
      let _self = this;
      _self.$axios
        .get("get_project_tags/" + this.project_id)
        .then((response) => {
          let data = response.data;
          if (data.success) {
            this.setProjectTags(data.tags);
          } else {
            this.error = true;
            this.message = data.message;
          }
        });
    },
    removeProjectTags(tagId) {
      this.loading = true;
      let _self = this;
      _self.$axios.delete("delete_project_tags/" + tagId).then((response) => {
        let data = response.data;
        if (data.success) {
          this.getProjectTags();
          this.loading = false;
        } else {
          this.error = true;
          this.message = data.message;
          this.loading = false;
        }
      });
    },
    addTaskTag(taskId, projectTagId, statusId) {
      this.loading = true;
      let _self = this;
      _self.$axios
        .post("add_task_tags/" + projectTagId, { task_id: taskId })
        .then((response) => {
          let data = response.data;
          if (data.success) {
            // this.taskTags = data.tags
            this.loading = false;
            this.newTag.name = "";
            this.newTag.color = "#006EFF";
            let statusIndex = this.tasksByListStatus.findIndex(
              (status) => status.id == statusId
            );
            let statusData = this.tasksByListStatus.filter((status) => {
              if (status.id == statusId) {
                return status;
              }
            });
            let tasks = [];
            tasks = statusData[0].tasks;
            let taskIndex = tasks.findIndex((task) => task.task_id == taskId);

            if (taskIndex != -1) {
              tasks[taskIndex].task_tags.push(response.data.tags);
            }

            if (statusIndex != -1) {
              let tempTasksByListStatus = [...this.tasksByListStatus];

              tempTasksByListStatus[statusIndex] = {
                ...tempTasksByListStatus[statusIndex],
                tasks: tasks,
              };

              this.tasksByListStatus = tempTasksByListStatus;
            }
          } else {
            this.error = true;
            this.message = data.message;
            this.loading = false;
          }
        });
    },
    removeTaskTags(tagId, taskId, statusId) {
      let _self = this;
      _self.$axios.delete("remove_task_tags/" + tagId).then((response) => {
        let data = response.data;
        if (data.success) {
          this.taskTags = this.taskTags.filter((item) => {
            if (tagId != item.id) {
              return item;
            }
          });

          let statusIndex = this.tasksByListStatus.findIndex(
            (status) => status.id == statusId
          );
          let statusData = this.tasksByListStatus.filter((status) => {
            if (status.id == statusId) {
              return status;
            }
          });
          let tasks = [];
          tasks = statusData[0].tasks;
          let taskIndex = tasks.findIndex((task) => task.task_id == taskId);

          if (taskIndex != -1) {
            tasks[taskIndex].task_tags.filter((tags, index) => {
              if (tags.id == tagId) {
                tasks[taskIndex].task_tags.splice(index, 1);
              }
            });
          }

          if (statusIndex != -1) {
            let tempTasksByListStatus = [...this.tasksByListStatus];

            tempTasksByListStatus[statusIndex] = {
              ...tempTasksByListStatus[statusIndex],
              tasks: tasks,
            };

            this.tasksByListStatus = tempTasksByListStatus;
          }
        } else {
          this.error = true;
          this.message = data.message;
        }
      });
    },

    handleSocketAddTask(data) {
      let statusIndex = this.tasksByListStatus.findIndex(
        (status) => status.id == data.status_id
      );
      let statusData = this.tasksByListStatus[statusIndex];
      statusData.tasks.push(data);
      const updatedData = this.tasksByListStatus;
      updatedData[statusIndex] = statusData;
      this.tasksByListStatus = updatedData;
    },
    handleSocketAddPriority(data) {
      let statusIndex = this.tasksByListStatus.findIndex(
        (status) => status.id == data.status_id
      );
      let statusData = this.tasksByListStatus.filter((status) => {
        if (status.id == data.status_id) {
          return status;
        }
      });

      let tasks = [];
      tasks = statusData[0]?.tasks || [];

      let taskIndex = tasks.findIndex((task) => task.task_id == data.task_id);

      if (taskIndex != -1) {
        tasks[taskIndex].task_priority = data.task_priority;
      }

      if (statusIndex != -1) {
        let tempTasksByListStatus = [...this.tasksByListStatus];

        tempTasksByListStatus[statusIndex] = {
          ...tempTasksByListStatus[statusIndex],
          tasks: tasks,
        };

        this.tasksByListStatus = tempTasksByListStatus;
      }
    },
    handleSocketAddAssignee(data) {
      let statusIndex = this.tasksByListStatus.findIndex(
        (status) => status.id == data.status_id
      );
      let statusData = this.tasksByListStatus.filter((status) => {
        if (status.id == data.status_id) {
          return status;
        }
      });
      let tasks = [];
      tasks = statusData[0]?.tasks || [];
      let taskIndex = tasks.findIndex((task) => task.task_id == data.task_id);

      if (taskIndex != -1) {
        if (tasks[taskIndex] && tasks[taskIndex].assignees) {
          tasks[taskIndex].assignees = data?.assignees;
        }
        if (statusIndex != -1) {
          let tempTasksByListStatus = [...this.tasksByListStatus];

          tempTasksByListStatus[statusIndex] = {
            ...tempTasksByListStatus[statusIndex],
            tasks: tasks,
          };

          this.tasksByListStatus = tempTasksByListStatus;
        }
      }
    },
    handleSocketTaskDetailUpadte(data) {
      let updateTask = this.tasksByListStatus.map((item) => {
        if (item?.id == data.status_id) {
          item.tasks = item?.tasks?.map((task) => {
            if (task.task_id == data.task_id) {
              task = data;
            }
            return task;
          });
        }
        return item;
      });
      this.tasksByListStatus = updateTask;
      // this.getListTasksByStatus();
    },
  },
  mounted() {
    if (this.list_id) {
      this.getListTasksByStatus();
    }

    this.getEmployees();
    this.$ws.$on("add_task", this.handleSocketAddTask);
    this.$ws.$on("add_priority", this.handleSocketAddPriority);
    this.$ws.$on("add_assignee", this.handleSocketAddAssignee);
    this.$ws.$on("update_task", this.handleSocketTaskDetailUpadte);
    this.getProjectTags();
  },
};
</script>
<style lang="css">
@import "../../../assets/kanban.css";

.kanban-board {
  width: calc(100vw - 30px);
  display: flex;
  padding: 20px;
  overflow: auto;
  gap: 20px;
  background-color: #fafbfc;
}

.kanban-stages-reorder h2 {
  text-transform: uppercase;
  color: #333;
  font-weight: 500;
  padding: 10px;
  font-size: 0.8rem;
}

.kanban-stages {
  /* width: 30%; */
  width: 310px;
  min-width: 310px;
  height: 78vh !important;
  overflow: hidden;

  /* background-color: #fff; */
  /* border-radius: 10px; */
  /* padding: 15px; */
  /* box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24); */
}

.kanban-stages .h2 {
  text-transform: uppercase;
  margin-bottom: 20px;
  color: #333;
  font-weight: 500;
  padding: 10px;
  font-size: 0.8rem;
  justify-content: space-between;
}

.drag-area {
  min-height: 50px;
  height: 74vh;
  overflow: auto;
}

.drag-area::-webkit-scrollbar {
  height: 6px;
  width: 5px;
  padding: 10px;
}

.drag-area::-webkit-scrollbar-track {
  background-color: var(--border-color);
  border-radius: 10px;
}

.drag-area::-webkit-scrollbar-thumb {
  background-color: #c2c6ce;
  border-radius: 10px;
}

.kanban-item {
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.task-card {
  padding: 10px;
  margin: 10px 0 !important;
  border-radius: 5px;
}
</style>
