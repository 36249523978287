<template>
  <span>
    <!-- Task Details Dialog -->
    <v-dialog
      v-model="taskDetailsDialog"
      persistent
      max-width="100%"
      scrollable
      style="overflow-y: hidden !important"
    >
      <v-card height="100vh" style="border-radius: 10px; overflow-y: hidden">
        <v-card-text class="text--primary pa-3 pt-0 mt-0">
          <v-container class="pa-0 ma-0" fluid>
            <v-row
              class="lighten-3 mt-0 pt-1"
              :style="{ backgroundColor: '#002638' }"
            >
              <v-col cols="9" class="d-flex align-center" style="padding: 4px">
                <div v-if="editedItem.parent == null">
                  <h3
                    class="px-4 d-flex align-center"
                    :style="{
                      color: `${
                        isDark(editedItem.color) ? 'white' : 'black'
                      } !important`,
                    }"
                  >
                    {{ editedItem.projectName }}
                  </h3>
                </div>
                <div
                  v-else
                  @click="closeSubtaskDetails"
                  style="display: contents"
                >
                  <v-icon style="cursor: pointer"> mdi-chevron-left </v-icon>
                  <h3 class="px-2 d-flex align-center">
                    {{ editedItem.projectName
                    }}<v-icon size="16" class="mx-2">mdi-arrow-right</v-icon
                    >{{ parentTaskName }}
                  </h3>
                </div>
              </v-col>
              <v-col cols="3" class="task-head-right" style="padding: 4px">
                <v-btn icon class="" @click="closeTaskDetails">
                  <v-icon color="white"> mdi-close </v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="9" class="d-flex">
                <v-menu offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      :color="editedItem.status_color"
                      small
                      dark
                      flat
                      v-bind="attrs"
                      v-on="on"
                    >
                      {{ editedItem.status }}
                      <v-icon> mdi-chevron-down </v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item
                      v-for="status in statuses"
                      :key="status.id"
                      class="cursor-pointer text-uppercase"
                    >
                      <v-list-item-title
                        @click="
                          editedItem.status_id = status.id;
                          saveOrUpdate();
                        "
                        class="d-flex align-center"
                      >
                        <v-icon
                          size="10"
                          :style="{ color: status.color }"
                          class="mr-3"
                          >mdi-circle</v-icon
                        >
                        <span>{{ status.name }}</span>
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
                <v-menu offset-y min-width="250px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="grey"
                      depressed
                      small
                      dark
                      icon
                      outlined
                      v-bind="attrs"
                      v-on="on"
                      class="ml-4 mr-2"
                      @click="filterMember(editedItem)"
                    >
                      <v-img
                        src="../../assets/icons/AddAssigneeIcon.svg"
                        max-width="32"
                        max-height="32"
                        width="32"
                        height="32"
                      />
                    </v-btn>
                  </template>
                  <v-text-field
                    @click.native.stop
                    v-model="internalAssigneeSearch"
                    prepend-inner-icon="mdi-magnify"
                    class="py-1 white"
                    solo
                    flat
                    @input="debouncedInternalSearch(editedItem)"
                    placeholder="Search..."
                    hide-details=""
                    dense
                  ></v-text-field>
                  <v-divider class="#878DAB" />
                  <v-list>
                    <v-list-item
                      v-for="member in filteredMembers"
                      :key="member.id"
                      @click="saveAssignee(member.employee.id, true)"
                    >
                      <v-list-item-avatar>
                        <Avatar
                          size="30px"
                          :color="member.employee?.color"
                          :intials="member.employee?.intials"
                          :profile_url="member.employee?.profile_url"
                        />
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title class="cursor-pointer">{{
                          member.employee.name
                        }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item v-if="filteredMembers.length == 0">
                      No Assignees
                    </v-list-item>
                  </v-list>
                </v-menu>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      outlined
                      class="ml-3"
                      v-show="
                        editedItem.status != 'Closed' && havingClosedStatus
                      "
                      @click="changeTaskStatus(editedItem.task_id)"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon> mdi-check </v-icon>
                    </v-btn>
                  </template>
                  <span>Close Task</span>
                </v-tooltip>
                <div
                  v-if="editedItem.displayAssignees.length > 0"
                  class="ml-3 mr-4"
                >
                  <v-tooltip
                    bottom
                    v-for="(assignee, i) in editedItem.displayAssignees"
                    :key="assignee.id"
                  >
                    <template v-if="i < 5" v-slot:activator="{ on, attrs }">
                      <div class="asssignee-container" v-if="assignee.employee">
                        <v-avatar
                          :color="assignee.employee.color"
                          class="avatar-icon white--text team-name-avatars cursor-pointer"
                          size="32"
                          v-bind="attrs"
                          v-on="on"
                        >
                          <img
                            v-if="assignee.employee.profile_url"
                            alt="image"
                            :src="assignee.employee.profile_url"
                            width="22"
                          />
                          <span v-else class="fontsize-11">{{
                            assignee.employee.intials
                          }}</span>
                        </v-avatar>

                        <v-icon
                          dark
                          x-small
                          @click="
                            removeAssignee(
                              editedItem.task_id,
                              assignee.employee.id,
                              true
                            )
                          "
                          class="remove-icon-in-dialog rounded-pill"
                        >
                          mdi-close
                        </v-icon>
                      </div>
                    </template>
                    <span>{{
                      assignee.employee ? assignee.employee.name : null
                    }}</span>
                  </v-tooltip>
                </div>
                <v-btn
                  class="ml-2"
                  outlined
                  icon
                  v-if="editedItem.displayAssignees.length > 5"
                >
                  +{{ editedItem.displayAssignees.length - 5 }}
                </v-btn>
                <v-menu offset-y transition="scale-transition">
                  <template #activator="{ on }" v-if="setPriority">
                    <v-tooltip bottom>
                      <template #activator="{ on: onTooltip }">
                        <span
                          class="ml-3 cursor-pointer"
                          v-on="{ ...on, ...onTooltip }"
                        >
                          <FlagIcon
                            :size="32"
                            :color="setPriority.color"
                          ></FlagIcon>
                        </span>
                      </template>
                      <span>{{ setPriority.name }}</span>
                    </v-tooltip>
                  </template>
                  <template #activator="{ on }" v-else>
                    <v-tooltip bottom>
                      <template #activator="{ on: onTooltip }">
                        <span
                          class="ml-3 cursor-pointer"
                          v-on="{ ...on, ...onTooltip }"
                        >
                          <FlagIcon :size="32" :color="'#c1c1c1'"></FlagIcon>
                        </span>
                      </template>
                      <span>Set Priority</span>
                    </v-tooltip>
                  </template>
                  <v-list>
                    <v-list-item v-for="item in priorities" :key="item.id">
                      <v-list-item-title
                        class="cursor-pointer"
                        @click="setPrioritys(item)"
                        ><span class="mr-2">
                          <v-icon :color="item.color">
                            mdi-flag-variant-outline</v-icon
                          > </span
                        >{{ item.name }}</v-list-item-title
                      >
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-title
                        class="cursor-pointer"
                        @click="setPrioritys('')"
                      >
                        <v-icon> mdi-close</v-icon> Clear
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>

                <span class="ml-3" v-if="editedItem.parent == null">
                  <v-menu offset-y transition="scale-transition">
                    <template #activator="{ on }">
                      <v-tooltip bottom>
                        <template #activator="{ on: onTooltip }">
                          <v-btn
                            class="ml-2"
                            outlined
                            icon
                            @click="moveTaskDialog = true"
                            max-width="32"
                            max-height="32"
                            width="32"
                            height="32"
                            style="border: 1px dashed; border-color: #c1c1c1"
                            v-on="{ ...on, ...onTooltip }"
                          >
                            <v-img
                              src="../../assets/icons/FileAudio.svg"
                              max-width="24"
                              max-height="24"
                              width="24"
                              height="24"
                            />
                          </v-btn>
                        </template>
                        <span>Move Task</span>
                      </v-tooltip>
                    </template>
                  </v-menu>
                </span>
                <span class="ml-3">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        class="ml-2"
                        outlined
                        icon
                        v-on="on"
                        @click="copyURL"
                        max-width="32"
                        max-height="32"
                        width="32"
                        height="32"
                        style="border: 1px dashed; border-color: #c1c1c1"
                      >
                        <v-img
                          src="../../assets/icons/Link.svg"
                          max-width="24"
                          max-height="24"
                          width="24"
                          height="24"
                        />
                      </v-btn>
                    </template>
                    <span>Copy Url</span>
                  </v-tooltip>
                </span>
                <span class="ml-3">
                  <v-menu
                    :ref="'menu' + editedItem.task_id"
                    v-model="menu[editedItem.task_id]"
                    :close-on-content-click="false"
                    :return-value.sync="editedItem.due_date"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template #activator="{ on }">
                      <v-tooltip bottom>
                        <template #activator="{ on: onTooltip }">
                          <v-btn
                            class="ml-2"
                            outlined
                            icon
                            max-width="32"
                            max-height="32"
                            width="32"
                            height="32"
                            style="border: 1px dashed; border-color: #c1c1c1"
                            v-on="{ ...on, ...onTooltip }"
                          >
                            <v-img
                              src="../../assets/icons/due_data.svg"
                              max-width="19"
                              max-height="19"
                              width="19"
                              height="19"
                            />
                          </v-btn>
                        </template>
                        <span>{{
                          editedItem.due_date
                            ? humanReadableDate(
                                editedItem.due_date,
                                "DD-MM-YYYY"
                              )
                            : ""
                        }}</span>
                      </v-tooltip>
                    </template>

                    <v-date-picker
                      v-model="editedItem.due_date"
                      no-title
                      scrollable
                    >
                      <v-spacer></v-spacer>
                      <v-btn
                        text
                        color="primary"
                        @click="menu[editedItem.task_id] = false"
                      >
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="
                          saveDueData(editedItem.task_id, editedItem.due_date)
                        "
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </span>
              </v-col>

              <v-col cols="3" class="task-head-right">
                <span class="grey--text">
                  <v-icon> mdi-clock-outline </v-icon>
                  {{ editedItem.created_at }}</span
                >
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="7" class="task-details-container">
                <v-card-text>
                  <div class="mb-3 d-flex align-center">
                    <v-menu offset-y min-width="250px">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn icon small v-bind="attrs" v-on="on">
                          <v-icon>mdi-tag-multiple-outline</v-icon>
                        </v-btn>
                      </template>
                      <div class="white">
                        <v-text-field
                          v-model="newTag.name"
                          @click.native.stop
                          dense
                          hide-details
                          solo
                          flat
                          placeholder="Add New Tag"
                          class="subtitle-2 py-1"
                        >
                          <template v-slot:prepend-inner>
                            <v-menu
                              top
                              nudge-bottom="300"
                              nudge-left="16"
                              :close-on-content-click="false"
                            >
                              <template v-slot:activator="{ on }">
                                <div :style="swatchStyle" v-on="on"></div>
                              </template>
                              <v-card>
                                <v-card-text class="pa-0">
                                  <v-color-picker v-model="newTag.color" flat />
                                </v-card-text>
                              </v-card>
                            </v-menu>
                          </template>
                          <template v-slot:append>
                            <v-btn
                              @click="storeNewTag()"
                              color="primary"
                              x-small
                              :style="{
                                marginTop: '4px',
                                backgroundColor: 'white',
                              }"
                            >
                              save
                            </v-btn>
                          </template>
                        </v-text-field>
                        <v-divider class="#878DAB" />
                        <v-list>
                          <v-list-item
                            v-for="tags in projectTags"
                            :key="tags.id"
                            style="min-height: 30px; max-height: 40px"
                          >
                            <v-list-item-icon style="margin: 8px 16px 8px 0">
                              <v-icon :color="tags.color"
                                >mdi-tag-multiple</v-icon
                              >
                            </v-list-item-icon>
                            <v-list-item-content
                              @click="addTaskTag(blockTaskId, tags.id)"
                            >
                              <v-list-item-title class="cursor-pointer">
                                {{ tags.name }}
                              </v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-action>
                              <v-btn
                                x-small
                                icon
                                @click="removeProjectTags(tags.id)"
                              >
                                <v-icon>mdi-close</v-icon>
                              </v-btn>
                            </v-list-item-action>
                          </v-list-item>
                          <v-list-item v-if="projectTags.length == 0">
                            No Tags
                          </v-list-item>
                        </v-list>
                      </div>
                    </v-menu>
                    <div
                      v-for="taskTag in taskTags"
                      :key="taskTag.id"
                      class="ml-2"
                    >
                      <span
                        class="d-flex align-center"
                        :style="{
                          backgroundColor: `rgba(${parseInt(
                            taskTag.projectTags.color.slice(1, 3),
                            16
                          )}, ${parseInt(
                            taskTag.projectTags.color.slice(3, 5),
                            16
                          )}, ${parseInt(
                            taskTag.projectTags.color.slice(5),
                            16
                          )}, 0.1)`,
                          padding: '4px 10px',
                          borderRadius: '5px',
                          fontWeight: '700',
                          color: taskTag.projectTags.color,
                        }"
                        >{{ taskTag.projectTags.name }}
                        <v-btn
                          icon
                          width="14px"
                          height="14px"
                          class="ml-3"
                          style="background-color: white"
                          @click="removeTaskTags(taskTag.id)"
                        >
                          <v-icon style="font-size: 14px">mdi-close</v-icon>
                        </v-btn>
                      </span>
                    </div>
                  </div>
                  <v-textarea
                    v-model="editedItem.name"
                    rows="1"
                    placeholder="Name"
                    solo
                    flat
                    background-color="#FAFBFC"
                    outlined
                    auto-grow
                    class="black--text subtitle-1"
                    @input="debouncedText"
                    @change="saveOrUpdate"
                    :error="errors.name != null"
                    :error-messages="errors.name"
                  >
                  </v-textarea>
                  <div
                    class="mb-5 pa-5"
                    style="
                      background-color: #fafbfc !important;
                      border: 1px solid #f0f4f8 !important;
                      border-radius: 5px !important;
                    "
                  >
                    <div class="mb-5">
                      <span class="span_text">Description</span>
                    </div>
                    <TinyEditor
                      v-if="!task_loading"
                      editorId="DescriptionInput"
                      ref="DescriptionInput"
                      placeholder="Description"
                      v-model="editedItem.description"
                      type="simple"
                      @input="debouncedText"
                      :readonly="false"
                      style="border: 0px"
                    >
                    </TinyEditor>
                  </div>
                  <v-expansion-panels
                    v-model="panel"
                    style="
                      border: 1px solid #f0f4f8 !important;
                      border-radius: 5px !important;
                    "
                    class="elevation-0"
                    flat
                    v-if="editedItem.parent == null && subTaskOpen"
                  >
                    <v-expansion-panel>
                      <v-expansion-panel-header>
                        Sub Tasks ({{ subtasks.length }})
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-list-item
                          class="d-block"
                          v-for="subtask in subtasks"
                          :key="subtask.id"
                        >
                          <!-- <v-list-item-content> -->
                          <div class="row">
                            <div class="col-2">
                              <template>
                                <v-btn
                                  x-small
                                  class="white--text elevation-0"
                                  :color="subtask.status_color"
                                  :max-width="100"
                                >
                                  <span
                                    class="text-truncate"
                                    style="max-width: 90px"
                                    >{{ subtask.status }}</span
                                  >
                                </v-btn>
                                <span></span>
                              </template>
                            </div>
                            <div class="col-7 d-flex">
                              <v-list-item-title
                                @click="
                                  isSubtaskUpdate = true;
                                  setTaskDetails(subtask.task_id);
                                "
                                style="cursor: pointer"
                              >
                                {{ subtask.name }}
                              </v-list-item-title>
                            </div>

                            <div class="col-2">
                              <div class="avatars-wrapper" style="float: right">
                                <InteractiveAvatar
                                  v-for="assignee in subtask.assignees"
                                  :key="assignee.employee?.id"
                                  :size="'30px'"
                                  :color="assignee.employee?.color"
                                  :intials="assignee.employee?.intials"
                                  :profile_url="assignee.employee?.profile_url"
                                  :name="assignee.employee?.name"
                                  @removeAssignee="
                                    removeSubTaskAssignee(
                                      subtask.task_id,
                                      assignee.employee?.id
                                    )
                                  "
                                />
                              </div>
                            </div>
                            <div class="col-1 d-flex">
                              <v-menu offset-y min-width="250px">
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                    color="grey"
                                    dark
                                    outlined
                                    icon
                                    small
                                    v-bind="attrs"
                                    v-on="on"
                                    class="add-assignes ml-0 mr-2 mt-0"
                                    style="margin-top: 2px"
                                    @click="filterSubTaskMember(subtask, true)"
                                  >
                                    <v-img
                                      src="../../assets/icons/AddAssigneeIcon.svg"
                                      max-width="32"
                                      max-height="32"
                                      width="32"
                                      height="32"
                                    />
                                  </v-btn>
                                </template>
                                <div class="white">
                                  <v-text-field
                                    @click.native.stop
                                    v-model="subTaskAssigneeSearch"
                                    prepend-inner-icon="mdi-magnify"
                                    class="py-1 white"
                                    solo
                                    flat
                                    @input="debouncedSubTaskAssignee(subtask)"
                                    placeholder="Search..."
                                    hide-details=""
                                    dense
                                  ></v-text-field>
                                  <v-divider class="#878DAB" />
                                  <v-list>
                                    <v-list-item
                                      v-for="member in filterSubTaskMembers"
                                      :key="member.id"
                                      @click="
                                        saveSubTaskAssignee(
                                          member.employee.id,
                                          subtask.task_id
                                        )
                                      "
                                    >
                                      <v-list-item-avatar>
                                        <Avatar
                                          size="30px"
                                          :color="member.employee?.color"
                                          :intials="member.employee?.intials"
                                          :profile_url="
                                            member.employee?.profile_url
                                          "
                                        />
                                      </v-list-item-avatar>
                                      <v-list-item-content>
                                        <v-list-item-title
                                          class="cursor-pointer"
                                        >
                                          {{ member.employee.name }}
                                        </v-list-item-title>
                                      </v-list-item-content>
                                    </v-list-item>
                                    <v-list-item
                                      v-if="filterSubTaskMembers.length == 0"
                                    >
                                      No Assignees
                                    </v-list-item>
                                  </v-list>
                                </div>
                              </v-menu>
                              <v-btn
                                icon
                                x-small
                                style="
                                  border: 1px dashed;
                                  border-color: #c1c1c1;
                                "
                                max-height="28"
                                width="28"
                                height="28"
                                @click="deleteTask(subtask.task_id)"
                              >
                                <TrashIcon :size="19" />
                              </v-btn>
                            </div>
                          </div>
                        </v-list-item>
                        <v-text-field
                          class="mt-4 mb-2"
                          outlined
                          dense
                          hide-details
                          v-model="subtaskEditedItem.name"
                        >
                          <template v-slot:append>
                            <!-- <v-fade-transition leave-absolute > -->
                            <v-progress-circular
                              v-if="loading"
                              size="24"
                              color="info"
                              indeterminate
                            ></v-progress-circular>
                            <v-menu offset-y>
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  color="grey"
                                  class="ml-3"
                                  depressed
                                  small
                                  dark
                                  icon
                                  outlined
                                  v-bind="attrs"
                                  v-on="on"
                                  @click="subfilterMember(editedItem)"
                                >
                                  <v-img
                                    src="../../assets/icons/AddAssigneeIcon.svg"
                                    max-width="28"
                                    max-height="28"
                                    width="28"
                                    height="28"
                                  />
                                </v-btn>
                              </template>
                              <v-text-field
                                @click.native.stop
                                v-model="subAssigneeSearch"
                                prepend-inner-icon="mdi-magnify"
                                class="py-1 white"
                                solo
                                flat
                                @input="debouncedSubAssignee(subtaskEditedItem)"
                                placeholder="Search..."
                                hide-details=""
                                dense
                              ></v-text-field>
                              <v-divider class="#878DAB" />
                              <v-list>
                                <v-list-item
                                  v-for="member in subTaskFilteredMembers"
                                  :key="member.id"
                                  @click="
                                    saveSubAssignee(member.employee.id, true)
                                  "
                                >
                                  <v-list-item-avatar>
                                    <Avatar
                                      size="30px"
                                      :color="member.employee?.color"
                                      :intials="member.employee?.intials"
                                      :profile_url="
                                        member.employee?.profile_url
                                      "
                                    />
                                  </v-list-item-avatar>
                                  <v-list-item-content>
                                    <v-list-item-title class="cursor-pointer">
                                      {{ member.employee.name }}
                                    </v-list-item-title>
                                  </v-list-item-content>
                                </v-list-item>
                                <v-list-item
                                  v-if="subTaskFilteredMembers.length == 0"
                                >
                                  No Assignees
                                </v-list-item>
                              </v-list>
                            </v-menu>
                            <div
                              class="ml-3 mr-2"
                              v-if="
                                subtaskEditedItem.displayAssignees.length > 0
                              "
                            >
                              <v-tooltip
                                bottom
                                v-for="(
                                  assignee, i
                                ) in subtaskEditedItem.displayAssignees"
                                :key="assignee.id"
                              >
                                <template
                                  v-if="i < 5"
                                  v-slot:activator="{ on, attrs }"
                                >
                                  <div
                                    class="asssignee-container"
                                    v-if="assignee.employee"
                                  >
                                    <v-avatar
                                      :color="assignee.employee.color"
                                      class="avatar-icon white--text team-name-avatars cursor-pointer"
                                      size="32"
                                      v-bind="attrs"
                                      v-on="on"
                                    >
                                      <img
                                        v-if="assignee.employee.profile_url"
                                        :src="assignee.employee.profile_url"
                                        width="22"
                                        alt="image"
                                      />
                                      <span v-else class="fontsize-11">{{
                                        assignee.employee.intials
                                      }}</span>
                                    </v-avatar>

                                    <v-icon
                                      dark
                                      x-small
                                      @click="
                                        removesubAssignee(
                                          assignee.employee.id,
                                          true
                                        )
                                      "
                                      class="remove-icon-in-dialog rounded-pill"
                                    >
                                      mdi-close
                                    </v-icon>
                                  </div>
                                </template>
                                <span>{{
                                  assignee.employee
                                    ? assignee.employee.name
                                    : null
                                }}</span>
                              </v-tooltip>
                              <v-btn
                                class="ml-2 mr-3"
                                outlined
                                icon
                                v-if="
                                  subtaskEditedItem.displayAssignees.length > 3
                                "
                              >
                                +{{
                                  subtaskEditedItem.displayAssignees.length - 3
                                }}
                              </v-btn>
                            </div>
                            <v-menu offset-y transition="scale-transition">
                              <template
                                #activator="{ on }"
                                v-if="subtaskEditedItem.priority"
                              >
                                <v-tooltip bottom>
                                  <template #activator="{ on: onTooltip }">
                                    <v-btn
                                      class="ml-3"
                                      depressed
                                      max-width="28"
                                      style="
                                        border: 1px dashed;
                                        border-color: #c1c1c1;
                                      "
                                      max-height="28"
                                      width="28"
                                      height="28"
                                      icon
                                      v-on="{ ...on, ...onTooltip }"
                                      ><v-icon
                                        :color="
                                          subtaskEditedItem.priority.color
                                        "
                                      >
                                        mdi-flag-variant-outline</v-icon
                                      ></v-btn
                                    >
                                  </template>
                                  <span>{{ setPriority.name }}</span>
                                </v-tooltip>
                              </template>
                              <template #activator="{ on }" v-else>
                                <v-tooltip bottom>
                                  <template #activator="{ on: onTooltip }">
                                    <v-btn
                                      class="ml-2"
                                      style="
                                        border: 1px dashed;
                                        border-color: #c1c1c1;
                                      "
                                      max-height="28"
                                      width="28"
                                      height="28"
                                      icon
                                      v-on="{ ...on, ...onTooltip }"
                                      ><v-icon :color="'#c1c1c1'">
                                        mdi-flag-variant-outline</v-icon
                                      ></v-btn
                                    >
                                  </template>
                                  <span>Set Priority</span>
                                </v-tooltip>
                              </template>
                              <v-list>
                                <v-list-item
                                  v-for="item in priorities"
                                  :key="item.id"
                                >
                                  <v-list-item-title
                                    class="cursor-pointer"
                                    @click="setSubPrioritys(item)"
                                    ><span class="mr-2">
                                      <v-icon :color="item.color">
                                        mdi-flag-variant-outline</v-icon
                                      > </span
                                    >{{ item.name }}</v-list-item-title
                                  >
                                </v-list-item>
                                <v-list-item>
                                  <v-list-item-title
                                    class="cursor-pointer"
                                    @click="setSubPrioritys('')"
                                  >
                                    <v-icon> mdi-close</v-icon> Clear
                                  </v-list-item-title>
                                </v-list-item>
                              </v-list>
                            </v-menu>
                            <v-btn
                              class="mx-3"
                              @click="saveOrUpdateSubTask(editedItem.task_id)"
                              x-small
                              color="primary"
                            >
                              Save
                            </v-btn>
                            <v-btn icon @click="closeAddSubTask()" x-small>
                              <v-icon>mdi-close</v-icon>
                            </v-btn>
                          </template>
                        </v-text-field>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>

                  <!-- Attachment module start -->

                  <div class="mt-5 mb-3">
                    <v-btn
                      color="info"
                      outlined
                      style="border-color: #4194f6; border-radius: 6px"
                      text
                      class="ml-1"
                      @click="() => (subTaskOpen = !subTaskOpen)"
                    >
                      + Add SUB TASKS ({{ subtasks.length }})
                    </v-btn>
                    <v-btn
                      color="info"
                      outlined
                      style="border-color: #4194f6; border-radius: 6px"
                      text
                      class="ml-7"
                      @click="setAttachmentDialog"
                    >
                      + Attachments ({{ attachments.length }})
                    </v-btn>
                  </div>
                  <v-container class="pa-0">
                    <v-row v-if="attachments.length > 0">
                      <v-col
                        cols="3"
                        sm="6"
                        md="3"
                        lg="3"
                        v-for="(attachment, index) in attachments"
                        :key="index"
                        class="attachment-col"
                      >
                        <v-hover v-slot="{ hover }">
                          <v-card
                            class="attachment-container"
                            elevation="0"
                            :class="{ 'on-hover': hover }"
                          >
                            <div
                              style="
                                max-width: 100%;
                                height: 140px;
                                background-color: #f1f3f5;
                              "
                            >
                              <img
                                class="attachment-img"
                                :src="attachment.url"
                                alt="image"
                                v-if="imageTypes.includes(attachment.filetype)"
                              />
                              <v-icon
                                style="display: flex; top: 50%"
                                x-large
                                v-else-if="
                                  zipFormates.includes(attachment.filetype)
                                "
                              >
                                mdi-zip-box
                              </v-icon>
                              <v-icon
                                style="display: flex; top: 35%"
                                x-large
                                v-else-if="
                                  documentFormates.includes(attachment.filetype)
                                "
                              >
                                mdi-zip-box
                              </v-icon>
                              <v-icon
                                style="display: flex; top: 35%"
                                x-large
                                v-else-if="
                                  audioFormatees.includes(attachment.filetype)
                                "
                              >
                                mdi-file-music
                              </v-icon>
                              <v-icon
                                style="display: flex; top: 35%"
                                x-large
                                v-else-if="
                                  videoFormates.includes(attachment.filetype)
                                "
                              >
                                mdi-file-video
                              </v-icon>
                              <v-icon
                                style="display: flex; top: 35%"
                                x-large
                                v-else
                              >
                                mdi-file
                              </v-icon>
                              <div
                                class="showEye"
                                v-if="imageTypes.includes(attachment.filetype)"
                                style="
                                  position: absolute;
                                  top: 32%;
                                  left: 50%;
                                  transform: translate(-50%, -50%);
                                "
                              >
                                <v-btn
                                  v-if="
                                    imageTypes.includes(attachment.filetype) ||
                                    videoFormates.includes(
                                      attachment.filetype
                                    ) ||
                                    audioFormatees.includes(
                                      attachment.filetype
                                    ) ||
                                    documentFormates.includes(
                                      attachment.filetype
                                    )
                                  "
                                  small
                                  icon
                                  color="success"
                                  target="_blank"
                                  :href="attachment.url"
                                  :class="{ 'show-btn': hover }"
                                >
                                  <EyeIcon :size="20" />
                                </v-btn>
                              </div>
                            </div>
                            <div class="attachment-footer">
                              <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                  <span
                                    class="attachment-title"
                                    v-bind="attrs"
                                    v-on="on"
                                    >{{ attachment.title }}</span
                                  >
                                </template>
                                <span>{{ attachment.title }}</span>
                              </v-tooltip>
                              <v-btn
                                v-if="attachment.user == user.id"
                                small
                                icon
                                color="error"
                                @click="attachmentDestory(attachment.id)"
                              >
                                <TrashIcon :size="19" />
                              </v-btn>

                              <v-btn
                                @click="downloadFile(attachment.url_filename)"
                                small
                                icon
                                color="#9d9a9a"
                              >
                                <v-icon> mdi-tray-arrow-down </v-icon>
                              </v-btn>
                            </div>
                          </v-card>
                        </v-hover>
                      </v-col>
                    </v-row>
                  </v-container>
                  <!-- Attachment module end -->

                  <!-- Task Activity Log -->
                  <div class="mt-5 mb-3">
                    <span class="black--text font-weight-bold">Activity</span>
                  </div>
                  <div v-if="showNoActivities">No activities available</div>
                  <v-list
                    v-if="showActivityLogs"
                    style="
                      border: 1px solid #f0f4f8 !important;
                      border-radius: 5px !important;
                    "
                  >
                    <v-list-item v-for="log in activityLogs" :key="log.id">
                      <v-list-item-avatar size="40px">
                        <Avatar
                          :size="'40px'"
                          :intials="log.user_intials"
                          :color="log.user_color"
                        />
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title class="body-2">
                          <span class="font-weight-bold">{{
                            log.user_name
                          }}</span>
                          {{ log.message }}
                          <span class="font-weight-bold">{{
                            log.module_name
                          }}</span>
                        </v-list-item-title>
                        <v-list-item-subtitle class="mt-1">
                          <div
                            v-if="
                              log.module_identifier ==
                              activityLogModuleIdentifiers.ASSIGNEE
                            "
                            style="display: flex; align-items: center"
                          >
                            <template v-if="log.from_module_id">
                              <Avatar
                                :size="'30px'"
                                :intials="log.from_emp_intials"
                                :color="log.from_emp_color"
                                class="mr-1"
                              />
                              {{ log.from_emp_name }}
                            </template>
                            <template v-if="log.to_module_id">
                              <Avatar
                                :size="'30px'"
                                :intials="log.to_emp_intials"
                                :color="log.to_emp_color"
                                class="mr-1"
                              />
                              {{ log.to_emp_name }}
                            </template>
                            <v-spacer></v-spacer>
                            <div>
                              {{ humanReadableDate(log.created_on, "LLL") }}
                            </div>
                          </div>
                          <div
                            v-if="
                              log.module_identifier ==
                              activityLogModuleIdentifiers.PRIORITY
                            "
                            style="display: flex; align-items: center"
                          >
                            <span
                              :style="{
                                color: log.from_prio_color,
                                backgroundColor: `${log.from_prio_color}20`,
                              }"
                              class="activity_block"
                              >{{ log.from_prio_name || "None" }}</span
                            >
                            <v-icon size="15px" class="mx-2"
                              >mdi-arrow-right</v-icon
                            >
                            <span
                              :style="{
                                color: log.to_prio_color,
                                backgroundColor: `${log.to_prio_color}20`,
                              }"
                              class="activity_block"
                              >{{ log.to_prio_name || "None" }}</span
                            >
                            <v-spacer></v-spacer>
                            <div>
                              {{ humanReadableDate(log.created_on, "LLL") }}
                            </div>
                          </div>
                          <div
                            v-if="
                              log.module_identifier ==
                              activityLogModuleIdentifiers.STATUS
                            "
                            style="display: flex; align-items: center"
                          >
                            <span
                              :style="{
                                color: log.from_status_color,
                                backgroundColor: `${log.from_status_color}20`,
                              }"
                              class="activity_block"
                              >{{ log.from_status_name || "None" }}</span
                            >
                            <v-icon size="15px" class="mx-2"
                              >mdi-arrow-right</v-icon
                            >
                            <span
                              :style="{
                                color: log.to_status_color,
                                backgroundColor: `${log.to_status_color}20`,
                              }"
                              class="activity_block"
                              >{{ log.to_status_name || "None" }}</span
                            >
                            <v-spacer></v-spacer>
                            <div>
                              {{ humanReadableDate(log.created_on, "LLL") }}
                            </div>
                          </div>
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                  <LoadingDot
                    v-if="isLoadingActivityLogs"
                    :bgColor="'#58b0f6'"
                  />
                  <div v-if="showLoadMoreActivities" class="text-center my-2">
                    <v-btn
                      depressed
                      small
                      class="primary"
                      @click="loadMoreActivities"
                      >Load More</v-btn
                    >
                  </div>
                  <div
                    v-if="showAllActivitiesSeen"
                    class="text-center my-2 font-weight-bold"
                  >
                    You've seen it all!
                  </div>
                  <!-- Task Activity Log End -->
                </v-card-text>
              </v-col>
              <v-col cols="5" :style="{ borderLeft: '1px solid lightgrey' }">
                <v-card flat>
                  <v-card-text class="comment-container" ref="commentContainer">
                    <v-row v-show="comments.length <= 0" class="ma-0">
                      <v-col cols="12" class="pa-8 ma-0 text-center">
                        No Comments Available
                      </v-col>
                    </v-row>
                    <v-row
                      v-for="comment in comments"
                      :key="comment.id"
                      class="ma-2 comment-card"
                    >
                      <v-col cols="1" class="pa-2 ma-0 align-self-start">
                        <v-tooltip bottom v-if="comment.employee">
                          <template v-slot:activator="{ on, attrs }">
                            <v-badge
                              overlap
                              bottom
                              dot
                              :color="comment.employee.is_online ? 'green' : ''"
                            >
                              <v-avatar
                                size="35"
                                v-show="comment.employee.profile_url != null"
                                v-bind="attrs"
                                v-on="on"
                                class="cursor-pointer mt-2"
                                style="background-color: white"
                              >
                                <img
                                  :src="comment.employee.profile_url"
                                  @error="fun(assignee).intials"
                                  :alt="comment.employee.name"
                                />
                              </v-avatar>
                              <v-avatar
                                v-show="comment.employee.profile_url == null"
                                :color="comment.employee.color"
                                class="white--text cursor-pointer mt-2"
                                size="35"
                                v-bind="attrs"
                                v-on="on"
                              >
                                <span style="font-size: 11px">{{
                                  comment.employee.intials
                                }}</span>
                              </v-avatar>
                            </v-badge>
                          </template>
                          <span>{{ comment.employee.name }}</span>
                        </v-tooltip>
                      </v-col>
                      <v-col cols="11" class="mt-3">
                        <div class="mb-4">
                          <span class="employeeName">{{
                            comment.employee.name
                          }}</span>
                          <span style="float: right">{{
                            getFormattedDate(new Date(comment.created_at))
                          }}</span>
                        </div>
                        <div
                          v-for="commentAttachment in comment.commentAttachments"
                          :key="commentAttachment.id"
                        >
                          <a
                            target="_blank"
                            class="mr-2 comment-link"
                            :href="commentAttachment.clickup_url + '?view=open'"
                          >
                            <v-img
                              v-if="
                                imageTypes.includes(commentAttachment.filetype)
                              "
                              :src="commentAttachment.clickup_url"
                              style="width: 97% !important"
                            >
                              <template v-slot:placeholder>
                                <v-row align="center" justify="center">
                                  <v-progress-circular
                                    indeterminate
                                    color="info"
                                  ></v-progress-circular>
                                </v-row>
                              </template>
                            </v-img>
                            <iframe
                              title="document"
                              v-else-if="
                                documentFormates.includes(
                                  commentAttachment.filetype
                                )
                              "
                              :src="`https://docs.google.com/viewer?url=${commentAttachment.clickup_url}&embedded=true&comment_id=${commentAttachment.comment_id}`"
                              width="97%"
                              height="100%"
                            ></iframe>
                            <iframe
                              title="video"
                              v-else-if="
                                videoFormates.includes(
                                  commentAttachment.filetype
                                )
                              "
                              :src="commentAttachment.clickup_url"
                              width="97%"
                              height="100%"
                            ></iframe>
                          </a>
                        </div>
                        <div
                          class="body-2 black--text text-justify white-space-preline body-2 comment--message"
                          v-html="getParsedComment(comment.message)"
                        ></div>
                        <span class="grey--text float-right caption">
                          <v-tooltip
                            bottom
                            v-if="comment.commentAttachments[0]"
                          >
                            <template v-slot:activator="{ on }">
                              <v-btn
                                icon
                                x-small
                                style="margin-left: 10px"
                                v-on="on"
                                @click="
                                  downloadCommentFile(
                                    comment.commentAttachments[0].url_filename
                                  )
                                "
                              >
                                <v-icon :size="19">
                                  mdi-tray-arrow-down
                                </v-icon>
                              </v-btn>
                            </template>
                            <span>Download File</span>
                          </v-tooltip>
                          <v-tooltip
                            bottom
                            v-if="
                              user.role == 'admin' ||
                              comment.employee_id == user.id
                            "
                          >
                            <template v-slot:activator="{ on }">
                              <v-btn
                                icon
                                x-small
                                style="margin-left: 10px"
                                v-on="on"
                                @click="deleteComment(comment)"
                              >
                                <TrashIcon :size="19" />
                              </v-btn>
                            </template>
                            <span>Delete Comment</span>
                          </v-tooltip>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                              <v-btn
                                icon
                                x-small
                                style="margin-left: 10px"
                                v-on="on"
                                @click="editComment(comment)"
                              >
                                <PencilIcon :size="19" />
                              </v-btn>
                            </template>
                            <span>Edit Comment</span>
                          </v-tooltip>
                        </span>
                      </v-col>
                    </v-row>
                  </v-card-text>
                  <v-card-actions>
                    <div class="comment-editor-container">
                      <Editor
                        v-model="comment"
                        :persons="persons"
                        @storeComment="storeComment"
                        :isComment="true"
                      />
                      <div class="row mt-1">
                        <div class="col-6" style="text-align: left !important">
                          <span class=""
                            >Attachments ({{ commentAttachments.length }})
                          </span>
                          <v-btn
                            color="info"
                            small
                            text
                            class="ml-2"
                            @click="setCommentAttachmentDialog"
                          >
                            + Add
                          </v-btn>
                        </div>
                        <div v-if="editCommentBtn" class="col-6 text-right">
                          <v-btn
                            small
                            color="dark"
                            class="mr-2"
                            @click="closeEditComment()"
                          >
                            Close
                          </v-btn>
                          <v-btn
                            small
                            color="primary"
                            @click="replaceComment()"
                          >
                            Edit
                            <v-icon x-small class="ml-1">mdi-pencil</v-icon>
                          </v-btn>
                        </div>
                        <div v-else class="col-6 text-right">
                          <v-btn small color="primary" @click="storeComment()">
                            send
                            <v-icon x-small class="ml-1">mdi-send</v-icon>
                          </v-btn>
                        </div>
                      </div>
                    </div>
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-overlay absolute :value="isErrorOccured" color="white" opacity="1">
          <div class="text-center">
            <p class="primary--text" style="font-size: 50px; margin: 0px">
              Oops !
            </p>
            <p class="grey--text subtitle-1">{{ errorMessage }}</p>
            <v-btn
              color="primary"
              class="mt-3"
              rounded
              depressed
              @click="closeTaskDetails"
              ><v-icon size="18" class="mr-3">mdi-arrow-left</v-icon>Go
              Back</v-btn
            >
          </div>
        </v-overlay>
      </v-card>
    </v-dialog>

    <!-- Move Task dialog -->
    <v-dialog v-model="moveTaskDialog" scrollable max-width="450px">
      <v-card>
        <v-card-title class="headline">Move Task</v-card-title>
        <v-sheet class="py-4 px-5 lighten-2">
          <v-text-field
            v-model="searchProject"
            label="Search Project"
            dense
            outlined
            hide-details
            clearable
            clear-icon="mdi-close-circle-outline"
          ></v-text-field>
        </v-sheet>
        <v-card-text style="height: 300px">
          <v-treeview
            :items="filteredItems"
            item-text="name"
            item-key="list_id"
            :search="searchProject"
            activatable
            @update:active="getActiveValue"
            open-on-click
          ></v-treeview>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="moveTaskDialog = false"
            >Cancel</v-btn
          >
          <v-btn color="red darken-1" text @click="moveTask(editedItem.task_id)"
            >Move</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="moveTaskStatusDialog" width="500">
      <v-card>
        <v-card-title class="primary white--text"> Select Status </v-card-title>
        <v-card-text class="mt-2 pb-0">
          <v-col cols="12" class="pt-0">
            <v-autocomplete
              v-model="selectMoveStatus"
              :items="moveStatuses"
              auto-select-first
              label="Select Status"
              item-text="name"
              item-value="id"
              deletable-chips
              class="mt-0 pt-0"
              outlined
              dense
            ></v-autocomplete>
          </v-col>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" @click="closeMoveTaskStatus"> Cancel </v-btn>
          <v-btn
            color="primary"
            :disabled="!selectMoveStatus"
            @click="moveTaskWithStatus"
          >
            Move
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <Upload
      ref="file-uploader"
      :dialog.sync="attachmentDialog"
      :multiple="true"
      :errors="errors"
      :loading="loading"
      @filesUploaded="storeTaskAttachment($event)"
    />

    <Upload
      ref="commentfile-uploader"
      :dialog.sync="commentAttachmentDialog"
      :multiple="true"
      :errors="errors"
      :loading="loading"
      @filesUploaded="storeCommentAttachment($event)"
    />
    <!-- dialog for displaying image and pdf -->
    <v-dialog v-model="iframeDialog" persistent>
      <v-card v-if="attachments[attachmentIndex] != null">
        <v-card-title>
          <span>{{ attachments[attachmentIndex].title }}</span>
          <v-spacer></v-spacer>
          <v-btn @click="closeIframe()" icon color="info">
            <v-icon> mdi-close </v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-row>
            <v-col cols="1" class="d-flex align-center">
              <v-btn
                icon
                color="info"
                v-if="attachmentIndex != 0"
                @click="attachmentIndex = attachmentIndex - 1"
              >
                <v-icon x-large> mdi-chevron-left </v-icon>
              </v-btn>
            </v-col>
            <v-col cols="10">
              <v-img
                v-if="
                  attachments[attachmentIndex].url &&
                  ['jpg', 'jpeg', 'png'].includes(
                    attachments[attachmentIndex].filetype
                  )
                "
                :src="defineSourceUrl(attachments[attachmentIndex])"
                class="h-auto mx-auto"
                contain
              >
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular
                      indeterminate
                      color="info"
                    ></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
              <iframe
                title="attachment"
                v-else-if="
                  attachments[attachmentIndex].url &&
                  ['pdf'].includes(attachments[attachmentIndex].filetype)
                "
                :src="defineSourceUrl(attachments[attachmentIndex])"
                class="h-80"
                width="100%"
                height="100%"
              ></iframe>
              <div v-else>
                <v-row class="my-5">
                  <v-col cols="12">
                    FileType Not Supported. You can download it by
                    <a
                      :href="
                        downloadAttachment(attachments[attachmentIndex].url)
                      "
                      class="text-decoration-none"
                      @click.stop
                    >
                      clicking here
                    </a>
                  </v-col>
                </v-row>
              </div>
            </v-col>
            <v-col cols="1" class="d-flex align-center">
              <v-btn
                icon
                color="info"
                v-if="attachmentIndex != attachments.length - 1"
                @click="attachmentIndex = attachmentIndex + 1"
              >
                <v-icon x-large> mdi-chevron-right </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-card v-if="subtaskAttachments[subtaskAttachmentIndex] != null">
        <v-card-title>
          <span>{{ subtaskAttachments[subtaskAttachmentIndex].title }}</span>
          <v-spacer></v-spacer>
          <v-btn @click="closeIframe()" icon color="info">
            <v-icon> mdi-close </v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-row>
            <v-col cols="1" class="d-flex align-center">
              <v-btn
                icon
                color="info"
                v-if="subtaskAttachmentIndex != 0"
                @click="subtaskAttachmentIndex = subtaskAttachmentIndex - 1"
              >
                <v-icon x-large> mdi-chevron-left </v-icon>
              </v-btn>
            </v-col>
            <v-col cols="10">
              <v-img
                v-if="
                  subtaskAttachments[subtaskAttachmentIndex].url &&
                  ['jpg', 'jpeg', 'png'].includes(
                    subtaskAttachments[subtaskAttachmentIndex].filetype
                  )
                "
                :src="
                  defineSourceUrl(subtaskAttachments[subtaskAttachmentIndex])
                "
                class="h-auto mx-auto"
              >
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular
                      indeterminate
                      color="info"
                    ></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
              <iframe
                title="subtaskAttachment"
                v-else-if="
                  subtaskAttachments[subtaskAttachmentIndex].url &&
                  subtaskAttachments[subtaskAttachmentIndex].filetype == 'pdf'
                "
                :src="
                  defineSourceUrl(subtaskAttachments[subtaskAttachmentIndex])
                "
                class="h-80"
                width="100%"
                height="100%"
              ></iframe>
              <div v-else>
                <v-row class="my-5">
                  <v-col cols="12">
                    FileType Not Supported. You can download it by
                    <a
                      :href="
                        downloadAttachment(
                          subtaskAttachments[subtaskAttachmentIndex].url
                        )
                      "
                      class="text-decoration-none"
                      @click.stop
                    >
                      clicking here
                    </a>
                  </v-col>
                </v-row>
              </div>
            </v-col>
            <v-col cols="1" class="d-flex align-center">
              <v-btn
                icon
                color="info"
                v-if="subtaskAttachmentIndex != subtaskAttachments.length - 1"
                @click="subtaskAttachmentIndex = subtaskAttachmentIndex + 1"
              >
                <v-icon x-large> mdi-chevron-right </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Attachment Dialog For Sub Task-->

    <Upload
      ref="sub-file-uploader"
      :dialog.sync="subtaskAttachmentDialog"
      :multiple="true"
      @filesUploaded="storeTaskAttachment($event, true)"
    />

    <!-- for success message -->
    <v-snackbar
      v-model="success"
      :timeout="3000"
      color="green accent-4"
      align-baseline="center"
    >
      {{ message }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="success = false"> Close </v-btn>
      </template>
    </v-snackbar>

    <!-- for success message -->
    <v-snackbar
      v-model="copied_snackbar"
      :timeout="1000"
      :centered="true"
      :width="100"
      max-width="100px"
      align-baseline="top"
      class="text-center"
    >
      Copied
    </v-snackbar>

    <!-- for error message -->
    <v-snackbar
      v-model="error"
      :timeout="3000"
      color="deep-orange accent-4"
      align-baseline="center"
    >
      {{ message }}

      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="error = false"> Close </v-btn>
      </template>
    </v-snackbar>

    <!-- Dialog for confirmation of deletion of the comment -->
    <v-dialog v-model="dialogDelete" max-width="400px">
      <v-card>
        <v-card-title class="headline"> Alert </v-card-title>
        <v-card-text class="subtitle-1"
          >Are you sure you want to delete this Comment?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
          <v-btn color="red darken-1" text @click="deleteItemConfirm"
            >Delete</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Dialog for confirmation of deletion of the Task -->
    <v-dialog v-model="dialogTaskDelete" max-width="400px">
      <v-card>
        <v-card-title class="headline"> Alert </v-card-title>
        <v-card-text class="subtitle-1"
          >Are you sure you want to delete this Sub Task?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeTaskDelete"
            >Cancel</v-btn
          >
          <v-btn color="red darken-1" text @click="deleteTaskItemConfirm"
            >Delete</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Add Sub Task -->
    <v-dialog v-model="addSubtaskDialog" width="600">
      <v-card>
        <v-card-title> Add Sub Task </v-card-title>
        <v-card-text>
          <v-textarea
            v-model="subtaskEditedItem.name"
            rows="1"
            outlined
            label="Name"
            auto-grow
            class="black--text subtitle-1"
            :error="subtaskErrors.name != null"
            :error-messages="subtaskErrors.name"
          >
          </v-textarea>
          <v-textarea
            v-model="subtaskEditedItem.description"
            rows="3"
            outlined
            label="Description"
            auto-grow
            class="black--text subtitle-1"
            :error="subtaskErrors.description != null"
            :error-messages="subtaskErrors.description"
          >
          </v-textarea>
          <v-select
            v-model="subtaskEditedItem.assignees"
            :items="members"
            item-text="employee.name"
            item-value="employee_id"
            label="Assignee"
            outlined
            dense
            multiple
            :error="subtaskErrors.assignees != null"
            :error-messages="subtaskErrors.assignees"
          >
          </v-select>
          <v-file-input
            v-model="subtaskEditedItem.attachments"
            accept="image/*,.pdf"
            label="Attachments"
            multiple
            chips
            :error="errors.attachments != null"
            :error-messages="errors.attachments"
          ></v-file-input>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="closeAddSubTask"
            :disabled="loading"
          >
            Close
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="saveOrUpdateSubTask()"
            :loading="loading"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </span>
</template>

<script>
import Vue from "vue";
import { mapMutations, mapState } from "vuex";
import moment from "moment";
import { debounce } from "lodash";
import TinyEditor from "../TinyEditor.vue";
import Upload from "../shared/UploadFile.vue";
import Editor from "../common/editor/Editor.vue";
import { replaceUsernameWithId } from "../../helper/editor";
import Avatar from "../common/basic/Avatar.vue";
import LoadingDot from "../common/basic/LoadingDot.vue";
import { activityLogModuleIdentifiers } from "../../helper/task";
import TrashIcon from "@/assets/icons/TrashIcon.vue";
import EyeIcon from "@/assets/icons/EyeIcon.vue";
import PencilIcon from "@/assets/icons/PencilIcon.vue";
import FlagIcon from "../../assets/icons/FlagIcon.vue";
import InteractiveAvatar from "../common/basic/InteractiveAvatar.vue";
import { isDark } from "../../helper/uttils";

export default {
  props: [
    "taskDetailsDialog",
    "block",
    "blockTaskId",
    "stage",
    "isUpdate",
    "project_id",
    "redirect",
    "redirectToRoute",
    "selectedlistId",
  ],
  data() {
    return {
      task_loading: true,
      copied_snackbar: false,
      filepondDailog: false,
      user: {},
      newTag: {
        name: null,
        color: "#006EFF",
        project_id: null,
      },
      newTask: {
        name: null,
        status: "OPEN",
        status_color: "#d3d3d3",
        assignees: [],
        displayAssignees: [],
        created_at: new Date(),
      },
      newTaskOpen: false,
      menu: {},
      stages: [],
      blocks: [],
      message: "",
      error: false,
      success: false,
      statuses: [],
      displayBlock: false,
      dialogDelete: false,
      deleteCommentId: "",
      displayAsInput: false,
      selectedStage: "",
      // project_id: this.$route.params.id,
      list_id: null,
      editedItem: {
        task_id: "",
        name: "",
        color: "",
        description: "",
        assignees: [],
        status: "",
        status_color: "",
        status_id: null,
        priority: "",
        due_date: "",
        parent: null,
        projectName: "",
        displayAssignees: [],
        created_at: new Date(),
      },
      loading: false,
      members: [],
      errors: [],
      list: {},
      comments: [],
      comment: "",
      editCommentBtn: false,
      editcommentData: {},
      displayAssignees: [],
      userOnline: "",
      parentTaskName: "",
      loadingNewList: false,
      addTaskDialog: false,
      havingClosedStatus: false,
      subtasks: [],
      subtaskDetailsDialog: false,
      isSubtaskUpdate: false,
      selectedSubtaskStage: "",
      addSubtaskDialog: false,
      subtaskComments: "",
      subtaskDisplayAssignees: [],
      subtaskErrors: [],
      deleteTaskId: "",
      dialogTaskDelete: false,
      isDeleted: "",
      subtaskEditedItem: {
        task_id: "",
        name: "",
        description: "",
        assignees: [],
        status: "",
        status_color: "",
        status_id: null,
        attachments: [],
        displayAssignees: [],
        priority: "",
        created_at: new Date(),
      },
      showCommnetEditor: false,
      subTaskDescriptionEditor: false,
      subtaskComment: "",
      setPriority: {
        color: "",
      },
      parent: "",
      subTaskOpen: false,
      hasParent: false,
      moveTaskDialog: false,
      moveTaskStatusDialog: false,
      moveTaskId: "",
      moveStatuses: [],
      selectMoveStatus: "",
      attachments: [],
      commentAttachments: [],
      subtaskAttachments: [],
      attachment: [],
      subtaskattachment: [],
      attachmentDialog: false,
      commentAttachmentDialog: false,
      subtaskAttachmentDialog: false,
      filter_assignees: [],
      commentLoader: false,
      attachmentLoader: false,
      subtaskLoader: false,
      assignees: [],
      filteredMembers: [],
      subTaskFilteredMembers: [],
      filterSubTaskMembers: [],
      subTaskAssigneeSearch: "",
      subAssigneeSearch: "",
      selectedSubTask: "",
      searchProject: "",
      selectedTask: "",
      selectedTaskDetails: {},
      subtaskAttachmentIndex: -1,
      attachmentIndex: -1,
      iframeDialog: false,
      projectGroupArray: [],
      movelistId: "",
      newGroup: {
        name: null,
        project_id: null,
        folder_id: null,
      },
      newGroupLoading: false,
      addGroupFormValid: true,
      nameRules: [(v) => !!v || "Group name is required"],
      viewSubTaks: false,
      subTaskParentId: null,
      newStatus: {
        name: null,
        color: "#006EFF",
        folder_id: null,
      },
      panel: 0,
      listType: "kanban",
      imageTypes: [
        "image/jpeg",
        "image/png",
        "image/gif",
        "image/x-icon",
        "image/svg+xml",
        "image/vnd.microsoft.icon",
      ],
      videoFormates: ["video/mp4", "video/webm"],
      audioFormatees: ["audio/mpeg"],
      zipFormates: [
        "application/vnd.rar",
        "application/x-7z-compressed",
        "application/zip",
      ],
      documentFormates: [
        "application/pdf",
        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        ".csv",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "application/vnd.ms-excel",
      ],
      mediaUrl: process.env.VUE_APP_UPLOAD_URL,
      open: [1, 2],
      treeFiltered: false,
      filteredTreeData: [],
      isErrorOccured: false,
      errorMessage: "",
      activityLogsPagination: {
        page: 1,
        rowsPerPage: 20,
        totalPages: 1,
        totalItems: 20,
      },
      activityLogs: [],
      isLoadingActivityLogs: false,
      activityLogModuleIdentifiers: activityLogModuleIdentifiers,
      internalAssigneeSearch: "",
      selectedAssignees: [],

      taskTags: [],
    };
  },
  computed: {
    ...mapState("tasks", ["syncProject", "priorities"]),
    ...mapState("projects", ["projectTags"]),

    showNoActivities() {
      return !this.isLoadingActivityLogs && !this.activityLogs.length;
    },
    showActivityLogs() {
      return this.activityLogs.length;
    },
    showLoadMoreActivities() {
      let { totalPages, page } = this.activityLogsPagination;

      return !this.isLoadingActivityLogs && page < totalPages;
    },
    showAllActivitiesSeen() {
      return (
        !this.isLoadingActivityLogs &&
        this.activityLogs.length &&
        this.activityLogs.length == this.activityLogsPagination.totalItems
      );
    },
    filteredItems() {
      if (this.searchProject) {
        // Apply filtering based on the filterText
        return this.projectGroupArray.filter((item) =>
          item.name?.toLowerCase().includes(this.searchProject.toLowerCase())
        );
      }
      // Return the original items if no filtering is applied
      return this.projectGroupArray;
    },
    persons() {
      return this.members.map((member) => {
        return {
          id: member.employee.id,
          label: member.employee?.name || "",
        };
      });
    },
    swatchStyle() {
      const { menu } = this;
      return {
        backgroundColor: this.newTag.color,
        cursor: "pointer",
        height: "15px",
        width: "15px",
        borderRadius: menu ? "50%" : "4px",
        transition: "border-radius 200ms ease-in-out",
        marginTop: "3px",
        marginRight: "3px",
      };
    },
  },
  components: {
    TinyEditor,
    Editor,
    Upload,
    Avatar,
    LoadingDot,
    TrashIcon,
    EyeIcon,
    PencilIcon,
    FlagIcon,
    InteractiveAvatar,
  },
  watch: {
    syncProject() {
      this.syncClickUpTasksToLocal();
    },
    "editedItem.description": function () {
      this.saveOrUpdate();
    },
    // fetch task acitivities when task id changes
    "editedItem.task_id": function (taskId) {
      if (taskId) {
        // clearing old activity logs
        this.activityLogs = [];

        // resetting pagination
        this.activityLogsPagination = {
          ...this.activityLogsPagination,
          page: 1,
          totalPages: 1,
        };

        // fetching new activity logs
        this.fetchTaskActivityLogs();
      }
    },
  },
  methods: {
    ...mapMutations("projects", ["setProjectTags"]),

    isDark(color) {
      return isDark(color);
    },
    debouncedInternalSearch: debounce(function (members) {
      const arrayColumn = (arr, n) => arr.map((x) => x[n]);
      let addAssigneelist = arrayColumn(
        members.displayAssignees,
        "employee_id"
      );
      this.filteredMembers = this.members.filter((item) => {
        let empName = item.employee.name.toLocaleLowerCase();
        if (
          empName?.includes(this.internalAssigneeSearch?.toLocaleLowerCase()) &&
          !addAssigneelist.includes(item.employee_id)
        ) {
          return item;
        }
      });
    }, 500),
    debouncedText: debounce(function () {
      this.saveOrUpdate();
    }, 1000),
    ...mapMutations("tasks", ["setSyncProject"]),

    downloadFile(IMG_URL) {
      const url = `download/${IMG_URL}`;
      this.$axios({
        url,
        method: "GET",
        responseType: "blob", // Set the response type to 'blob' to handle binary data
      })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", IMG_URL);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        })
        .catch((error) => {
          console.error("Failed to download the image", error);
        });
    },
    downloadCommentFile(IMG_URL) {
      const url = `download_comment_attachment/${IMG_URL}`;
      this.$axios({
        url,
        method: "GET",
        responseType: "blob", // Set the response type to 'blob' to handle binary data
      })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", IMG_URL);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        })
        .catch((error) => {
          console.error("Failed to download the image", error);
        });
    },
    async copyURL() {
      try {
        const location = window.location;
        const url = `${location.origin}${location.pathname}?list_id=${this.list_id}&task_id=${this.editedItem.task_id}`;

        await navigator.clipboard.writeText(url);
        this.copied_snackbar = true;
      } catch ($e) {
        alert("Cannot copy");
      }
    },
    getUser() {
      this.$axios.get("employee/" + this.user.id).then((response) => {
        let data = response.data;
        if (data.success) {
          this.userOnline = data.employee.is_online;
        }
      });
    },
    getActiveValue(value) {
      this.movelistId = value[0];
    },
    storeNewTag() {
      let objdata = {
        name: this.newTag.name,
        color: this.newTag.color,
        project_id: this.project_id,
      };
      let _self = this;
      _self.$axios.post("add_new_tags", objdata).then((response) => {
        let data = response.data;
        if (data.success) {
          this.setProjectTags([...this.projectTags, data.tags]);
        } else {
          this.error = true;
          this.message = data.message ? data.message : data.errors[0].message;
          this.loading = false;
        }
      });
    },
    getProjectTags() {
      if (this.projectTags?.length) {
        return;
      }
      let _self = this;
      _self.$axios
        .get("get_project_tags/" + this.project_id)
        .then((response) => {
          let data = response.data;
          if (data.success) {
            this.setProjectTags(data.tags);
          } else {
            this.error = true;
            this.message = data.message;
          }
        });
    },
    removeProjectTags(tagId) {
      this.loading = true;
      let _self = this;
      _self.$axios.delete("delete_project_tags/" + tagId).then((response) => {
        let data = response.data;
        if (data.success) {
          this.getProjectTags();
          this.loading = false;
        } else {
          this.error = true;
          this.message = data.message;
          this.loading = false;
        }
      });
    },
    addTaskTag(taskId, projectTagId) {
      this.loading = true;
      let _self = this;
      _self.$axios
        .post("add_task_tags/" + projectTagId, { task_id: taskId })
        .then((response) => {
          let data = response.data;
          if (data.success) {
            this.taskTags.push(data.tags);

            this.loading = false;
            this.newTag.name = "";
            this.newTag.color = "#006EFF";
          } else {
            this.error = true;
            this.message = data.message;
            this.loading = false;
          }
        });
    },
    getTaskTags() {
      let _self = this;
      _self.$axios.get("get_task_tags/" + this.blockTaskId).then((response) => {
        let data = response.data;
        if (data.success) {
          this.taskTags = data.tags;
        } else {
          this.error = true;
          this.message = data.message;
        }
      });
    },
    removeTaskTags(tagId) {
      let _self = this;
      _self.$axios.delete("remove_task_tags/" + tagId).then((response) => {
        let data = response.data;
        if (data.success) {
          this.taskTags = this.taskTags.filter((item) => {
            if (tagId != item.id) {
              return item;
            }
          });
        } else {
          this.error = true;
          this.message = data.message;
        }
      });
    },

    moveTask(taskId) {
      this.moveTaskStatusDialog = true;
      this.moveTaskId = taskId;
      let _self = this;
      _self.$axios
        .get("move_task_status/" + this.movelistId)
        .then((response) => {
          let data = response.data;
          if (data.success) {
            this.moveStatuses = data.statuses;
          }
        });
    },
    saveDueData(task_id, date) {
      this.menu[task_id] = false;
      this.$axios
        .put(`set_due_date/${task_id}`, {
          due_date: date,
        })
        .then((response) => {
          const resData = response.data;

          if (resData.success) {
            this.editedItem.due_date = resData.task.due_date;
          } else {
            this.error = true;
            this.message = "Something went wrong";
          }
        })
        .catch(() => {
          this.error = true;
          this.message = "Something went wrong";
        });
    },

    humanReadableDate(date, format) {
      return moment(date).format(format);
    },
    moveTaskWithStatus() {
      let _self = this;
      let data = {
        listIds: this.movelistId,
        statusName: this.selectMoveStatus,
      };
      this.loading = true;
      _self.$axios
        .put("move_task/" + this.moveTaskId, data)
        .then((response) => {
          let data = response.data;
          if (data.success) {
            _self.success = true;
            _self.message = data.message;
            this.moveTaskDialog = false;
            this.loading = false;
            this.closeTaskDetails();
          } else {
            _self.error = true;
            _self.message = data.message;
            this.loading = false;
          }
        });
    },

    closeMoveTaskStatus() {
      this.moveTaskStatusDialog = false;
      this.moveTaskId = "";
      this.movelistId = "";
      this.moveStatuses = [];
    },

    getProjectGroupList() {
      let _self = this;
      _self.$axios.get("project_group_list/").then((response) => {
        let data = response.data;
        if (data.success) {
          this.projectGroupArray = data.projects;
        }
      });
    },

    editComment(comment) {
      this.editCommentBtn = true;
      this.comment = comment.message;
      this.editcommentData = comment;
    },
    closeEditComment() {
      this.editCommentBtn = false;
      this.comment = "";
    },
    replaceComment() {
      this.loading = true;
      let data = {
        commentId: this.editcommentData.id,
        comment: this.comment,
        project_id: this.project_id,
      };
      this.$axios
        .put("tasks/" + this.editcommentData.task_id + "/comment_update", data)
        .then((response) => {
          this.loading = false;
          let data = response.data;

          if (data.success) {
            this.editCommentBtn = false;
            this.comment = "";
            this.success = true;
            this.message = data.message;
          } else {
            this.error = true;
            this.message = data.message;
          }
        });
    },

    closeNewTaskModule() {
      this.newTask = {
        name: null,
        status: "OPEN",
        status_color: "#d3d3d3",
        assignees: [],
        displayAssignees: [],
        created_at: new Date(),
      };
      this.newTaskOpen = false;
    },
    setNewTaskStatus(status) {
      this.newTask.status = status;
    },
    attachmentDestory(id, isSubTask = false) {
      let _self = this;
      _self.$axios.delete("remove-attachment/" + id).then((response) => {
        let data = response.data;
        if (data.success) {
          if (!isSubTask) {
            this.attachments = data.attachments;
          } else {
            this.subtaskAttachments = data.attachments;
          }
        } else {
          _self.error = true;
          _self.message = data.message;
        }
      });
    },
    init() {
      let _self = this;
      _self.loading = true;
      _self.$axios.get("list/" + this.list_id).then((response) => {
        let data = response.data;
        _self.loading = false;
        if (data.success) {
          _self.blocks = data.tasks;
          _self.list = data.list;
          _self.statuses = data.statuses;
          _self.assignees = data.assignees;
          if (_self.statuses.find((status) => status == "Closed")) {
            _self.havingClosedStatus = true;
          }
          _self.members = data.members;
        } else {
          _self.error = true;
          _self.message = data.message;
        }
      });
    },
    opensubTaskDialog(subTask, task) {
      this.isSubtaskUpdate = true;
      this.isUpdate = true;
      this.setSubtaskDetails("", subTask);
      this.setTaskDetails("", task);
    },
    hideShowSubTasks(parentId) {
      this.subTaskParentId = null;
      this.viewSubTaks = !this.viewSubTaks;
      if (this.viewSubTaks) {
        this.subTaskParentId = parentId;
      }
    },

    fun(employee_id) {
      let member = this.members.find((m) => {
        return m.employee_id == employee_id;
      });

      if (member) {
        return member.employee;
      }
      return {
        color: "#fff",
        intials: "NULL",
      };
    },

    invertColor(hexcolor) {
      // If a leading # is provided, remove it
      if (hexcolor.slice(0, 1) === "#") {
        hexcolor = hexcolor.slice(1);
      }
      // Convert to RGB value
      var r = parseInt(hexcolor.substr(0, 2), 16);
      var g = parseInt(hexcolor.substr(2, 2), 16);
      var b = parseInt(hexcolor.substr(4, 2), 16);
      // Get YIQ ratio
      var yiq = (r * 299 + g * 587 + b * 114) / 1000;
      // Check contrast
      return yiq >= 128 ? "black" : "white";
    },
    loadStages(statues) {
      var filtered = [];
      for (var i = 0; i < statues.length; i++) {
        filtered.push(statues[i].name);
      }
      return filtered;
    },
    changeTaskStatus(task_id, status = "Closed", isSubTask = false) {
      let _self = this;
      let statusData = this.statuses.find((s) => s.name == status);
      _self.loading = true;
      _self.$axios
        .put("tasks/" + task_id + "/changeStatus", {
          status: status,
          status_color: statusData.color,
        })
        .then((response) => {
          let data = response.data;
          _self.loading = false;
          if (data.success) {
            if (!isSubTask) {
              let item = this.blocks.find((b) => b.task_id === Number(task_id));
              if (item && item != undefined && item != null) {
                item.status = status;
                item.status_color = statusData.color;
              }
              _self.taskDetailsDialog = false;
            } else {
              _self.subtaskDetailsDialog = false;
            }
          } else {
            _self.error = true;
            _self.message = data.message;
          }
        });
    },

    deleteTask(taskId) {
      this.deleteTaskId = taskId;
      this.dialogTaskDelete = true;
    },
    deleteTaskItemConfirm() {
      let _self = this;
      // deleting task
      _self.loading = true;
      _self.$axios
        .delete(
          "tasks/" + _self.deleteTaskId + "?project_id=" + this.project_id
        )
        .then((response) => {
          let data = response.data;
          if (data.success) {
            let item = _self.subtasks.find(
              (i) => i.task_id == _self.deleteTaskId
            );

            if (item && item != undefined && item != null) {
              _self.subtasks.splice(_self.subtasks.indexOf(item), 1);
              _self.closeTaskDelete();
              _self.loading = false;
            }
          }
          //   } else {
          //     _self.error = true;
          //     _self.message = data.message;
          //   }
        });
    },
    closeTaskDelete() {
      this.deleteTaskId = "";
      this.dialogTaskDelete = false;
      this.hasParent = false;
    },
    mouseover() {
      this.displayBlock = true;
    },
    mouseleave() {
      this.displayBlock = false;
    },
    setPrioritys(priority) {
      this.setPriority = priority;
      this.saveOrUpdate();
    },
    setSubPrioritys(priority) {
      this.subtaskEditedItem.priority = priority;
    },
    setTaskDetails(task) {
      let _self = this;
      this.resetEditor();
      this.task_loading = true;
      _self.$axios
        .get(`tasks/${task}?projectId=${this.project_id}`)
        .then((response) => {
          if (response.data.success) {
            let data = response.data.data;
            this.list_id = data.list_id;
            this.init();
            this.isDeleted = data.is_deleted;
            if (this.list_id == this.selectedlistId) {
              if (this.isDeleted == 1) {
                this.isErrorOccured = true;
                this.errorMessage = "The task has either been deleted or moved";
              } else {
                let assignees = data.assignees.map((assignee) => {
                  return assignee.employee ? assignee.employee.id : null;
                });

                let formatedDate = moment(
                  data.created_at,
                  "DD/MM/YYYY HH:mm:ss"
                ).format("YYYY-MM-DD hh:mm:ss");
                let taskCreateDate = new Date(
                  Date.parse(`${formatedDate} UTC`)
                );
                this.editedItem = {
                  ...this.editedItem,
                  task_id: data.task_id,
                  name: data.name,
                  description: data.description,
                  assignees: assignees,
                  parent: data.parent,
                  displayAssignees: data.assignees,
                  status: data.status,
                  status_color: data.status_color,
                  due_date: data.due_date,
                  status_id: data.status_id,
                  created_at: moment(
                    taskCreateDate,
                    "DD/MM/YYYY hh:mm:ss"
                  ).format("LLL"),
                  projectName: data.project.name,
                };
                this.setPriority = data.taskpriority;
                this.getTaskComments();
                this.subtasks =
                  data.sub_tasks && data.sub_tasks.length > 0
                    ? data.sub_tasks
                    : [];
                this.parentTaskName = data.parent_task_detail
                  ? data.parent_task_detail.name
                  : "";
                this.getTaskAttachments();
                setTimeout(() => {
                  this.task_loading = false;
                }, 500);
              }
            } else {
              this.isErrorOccured = true;
              this.errorMessage = "List Not Found In This Project";
            }
          } else {
            this.editedItem.task_id = "";
            this.editedItem.name = "";
            this.editedItem.description = "";
            this.editedItem.assignees = [];
            this.addTaskDialog = true;

            // show error message
            this.isErrorOccured = true;
            this.errorMessage = response.data.message || "Something went wrong";
          }
        });
      this.errors = [];
    },
    setSubtaskDetails(stage = "", parentTaskId = "") {
      this.parent = parentTaskId;
      this.editedItem.status = stage;
      this.editedItem.task_id = "";
      this.subtaskEditedItem.name = "";
      this.subtaskEditedItem.description = "";
      this.editedItem.assignees = [];
      this.addSubtaskDialog = true;
    },
    resetEditor() {
      this.comment = "";
    },
    closeTaskDetails() {
      this.comments = [];
      this.editedItem.task_id = "";
      this.editedItem.name = "";
      this.editedItem.description = "";
      this.editedItem.assignees = [];
      this.editedItem.created_at = new Date();
      this.displayAssignees = [];
      this.attachments = [];
      this.errors = [];
      this.commentAttachments = [];
      this.subtasks = [];
      this.setPriority = {};

      if (this.redirect) {
        const route = this.redirectToRoute || `/projects/${this.list_id}/board`;
        this.$router.push(route).catch(() => {});
      }

      this.resetEditor();
      this.saveOrUpdate();
      if (this.$refs["DescriptionInput"]) {
        this.$refs["DescriptionInput"].reset();
      }
      this.$emit("closeTaskDetailModel");
    },
    closeAddSubTask() {
      this.subtaskEditedItem.name = "";
      this.subtaskEditedItem.assignees = [];
      this.subtaskEditedItem.displayAssignees = [];
      this.subtaskEditedItem.priority = "";
    },

    closeSubtaskDetails() {
      if (!this.isSubtaskUpdate) {
        this.selectedSubtaskStage = "";
        this.addSubtaskDialog = false;
      } else {
        this.subtaskDetailsDialog = false;
        this.saveOrUpdate();
      }
      this.isSubtaskUpdate = false;
      this.comments = [];
      this.editedItem.task_id = "";
      this.editedItem.name = "";
      this.editedItem.assignees = [];
      this.editedItem.created_at = new Date();
      this.displayAssignees = [];
      this.attachments = [];
      this.errors = [];
      this.commentAttachments = [];
      this.setPriority = {};
      this.subtasks = [];
      this.resetEditor();
      if (this.$refs["DescriptionInput"]) {
        this.$refs["DescriptionInput"].reset();
      }
      this.setTaskDetails(this.editedItem.parent);
    },
    saveOrUpdateSubTask(parentId) {
      this.parent = parentId;
      let _self = this;
      _self.loading = true;
      let formData = new FormData();
      formData.append("name", _self.subtaskEditedItem.name);
      formData.append("description", _self.subtaskEditedItem.description);
      formData.append("project_id", _self.project_id);
      formData.append("list_id", _self.list_id);
      formData.append("status", _self.selectedSubtaskStage);
      formData.append(
        "task_priority",
        _self.subtaskEditedItem.priority.id
          ? _self.subtaskEditedItem.priority.id
          : 0
      );
      formData.append("parentTaskId", _self.parent);

      if (_self.subtaskEditedItem.assignees.length > 0) {
        for (const assignee of _self.subtaskEditedItem.assignees) {
          formData.append("assignees[]", assignee);
        }
      }

      if (_self.subtaskEditedItem.attachments) {
        for (const attachment of _self.subtaskEditedItem.attachments) {
          formData.append("attachments[]", attachment);
        }
      }

      _self.$axios
        .post("tasks", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          _self.loading = false;
          let data = response.data;
          _self.subtaskErrors = [];
          if (!data.success) {
            if (data.errors) {
              for (const error of data.errors) {
                _self.subtaskErrors[error.field] = error.message;
              }
            } else {
              _self.error = true;
              _self.message = data.message;
            }
            this.isSubtaskUpdate = false;
          } else {
            _self.success = true;
            _self.message = data.message;
            this.addSubtaskDialog = false;
            _self.closeAddSubTask();
          }
        });
      // }
    },
    saveOrUpdate() {
      if (this.isUpdate) {
        if (!this.editedItem?.task_id) {
          return;
        }
        let _self = this;
        _self.loading = true;
        let formData = new FormData();
        formData.append("name", _self.editedItem.name);
        formData.append("description", _self.editedItem.description);
        formData.append("project_id", _self.project_id);
        formData.append("list_id", _self.list_id);
        formData.append("status_id", _self.editedItem.status_id);
        formData.append(
          "parentTaskId",
          _self.editedItem.parent ? _self.editedItem.parent : null
        );
        formData.append(
          "task_priority",
          this.setPriority ? this.setPriority.id : ""
        );
        _self.editedItem.assignees = [...new Set(_self.editedItem.assignees)];
        if (_self.editedItem.assignees.length > 0) {
          for (const assignee of _self.editedItem.assignees) {
            formData.append("assignees[]", assignee);
          }
        }

        _self.$axios
          .put(
            `tasks/${_self.editedItem.task_id}?project_id=${this.project_id}`,
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          )
          .then((response) => {
            _self.loading = false;
            let data = response.data;

            if (!data.success) {
              _self.errors = [];

              if (data.errors) {
                for (const error of data.errors) {
                  _self.errors[error.field] = error.message;
                }
              } else {
                _self.error = true;
                _self.message = data.message;
              }
            } else {
              this.editedItem.status = data.task.status;
              this.editedItem.status_color = data.task.status_color;

              let index = _self.blocks.findIndex(
                (block) => block.task_id == _self.editedItem.task_id
              );
              if (index != -1) {
                _self.blocks[index] = data.task; //updating the task details
              }
              if (!this.isUpdate) {
                _self.success = true;
                _self.message = data.message;
                _self.closeTaskDetails();
              }
            }
          });
      }
    },
    getEmployees() {
      let _self = this;
      _self.loading = true;
      _self.loadingNewList = true;
      _self.$axios
        .post("getmembers", {
          project_id: _self.project_id,
        })
        .then((res) => {
          let data = res.data;
          _self.loading = false;
          _self.loadingNewList = false;

          if (data.success) {
            _self.members = data.members;
          } else {
            _self.snackbarError = true;
            _self.message = data.message;
          }
        });
    },
    getTaskComments(isSubTask = false) {
      let _self = this;
      _self.commentLoader = true;
      let task_id = !isSubTask
        ? _self.editedItem.task_id
        : _self.subtaskEditedItem.task_id;

      _self.$axios.get("tasks/" + task_id + "/comments").then((response) => {
        let data = response.data;
        _self.commentLoader = false;

        if (data.success) {
          if (!isSubTask) {
            _self.comments = data.comments;
          } else {
            _self.subtaskComments = data.comments;
          }
          setTimeout(() => {
            _self.$refs.commentContainer.scrollTop =
              _self.$refs.commentContainer.scrollHeight;
          }, 100);
        } else {
          _self.error = true;
          _self.message = data.message;
        }
      });
    },
    getFormattedDate(date) {
      return moment.utc(new Date(date)).fromNow();
    },
    storeComment(isSubTask = false) {
      let _self = this;
      _self.loading = true;
      let task_id = !isSubTask
        ? _self.editedItem.task_id
        : _self.subtaskEditedItem.task_id;
      let formData = new FormData();

      let comments = !isSubTask ? _self.comment : _self.subtaskComment;

      comments = replaceUsernameWithId(comments);

      formData.append("comment", comments);
      formData.append("project_id", this.project_id);

      for (let index = 0; index < this.commentAttachments.length; index++) {
        const file = this.commentAttachments[index];
        formData.append("commentAttachments[" + index + "]", file);
      }

      _self.$axios
        .post("tasks/" + task_id + "/comments", formData)
        .then((response) => {
          _self.loading = false;
          let data = response.data;

          if (data.success) {
            if (!isSubTask) {
              _self.comment = "";
              this.commentAttachments = [];

              this.resetEditor();

              setTimeout(() => {
                _self.$refs.commentContainer.scrollTop =
                  _self.$refs.commentContainer.scrollHeight;
              }, 100);
            } else {
              _self.subtaskComment = "";
              _self.subtaskComments.push(data.comment);
              setTimeout(() => {
                _self.$refs.subtaskcommentContainer.scrollTop =
                  _self.$refs.subtaskcommentContainer.scrollHeight;
              }, 100);
            }
            _self.success = true;
            _self.message = data.message;
          } else {
            if (data.errors) {
              if (!isSubTask) {
                for (const error of data.errors) {
                  _self.errors[error.field] = error.message;
                }
              } else {
                for (const error of data.errors) {
                  _self.subtaskErrors[error.field] = error.message;
                }
              }
            } else {
              _self.error = true;
              _self.message = data.message;
            }
          }
        });
    },
    getSubTasks() {
      let _self = this;
      _self.subtaskLoader = true;
      _self.$axios
        .get("tasks/" + _self.editedItem.task_id + "/subtasks")
        .then((response) => {
          let data = response.data;
          _self.subtaskLoader = false;
          if (data.success) {
            _self.subtasks = data.subtasks;
          } else {
            _self.error = true;
            _self.message = data.message;
          }
        });
    },
    getTaskAttachments(isSubTask = false) {
      let _self = this;
      let task_id = !isSubTask
        ? _self.editedItem.task_id
        : _self.subtaskEditedItem.task_id;
      _self.attachmentLoader = true;

      _self.$axios.get("tasks/" + task_id + "/attachments").then((response) => {
        let data = response.data;
        _self.attachmentLoader = false;

        if (data.success) {
          if (!isSubTask) {
            _self.attachments = data.attachments;
          } else {
            _self.subtaskAttachments = data.attachments;
          }
        } else {
          _self.error = true;
          _self.message = data.message;
        }
      });
    },
    storeTaskAttachment(files, isSubTask = false) {
      let _self = this;
      let task_id = !isSubTask
        ? _self.editedItem.task_id
        : _self.subtaskEditedItem.task_id;
      let formData = new FormData();
      _self.loading = true;

      for (let index = 0; index < files.length; index++) {
        const file = files[index];
        formData.append("attachments[" + index + "]", file);
      }
      formData.append("project_id", this.project_id);
      _self.$axios
        .post("tasks/" + task_id + "/attachments", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          let data = response.data;
          _self.loading = false;

          if (data.success) {
            if (!isSubTask) {
              _self.closeAttachmentDialog();
              this.attachments = [
                ...this.attachments,
                ...response.data.attachments,
              ];
              this.$refs["file-uploader"].closeDialog();
            } else {
              for (const element of data.attachments) {
                const attachment = element;
                _self.subtaskAttachments.push(attachment);
              }
              _self.closeSubtaskAttachmentDialog();
              this.$refs["sub-file-uploader"].closeDialog();
            }
          } else {
            _self.subtaskErrors = [];
            _self.errors = [];
            if (data.errors) {
              if (isSubTask) {
                for (const element of data.errors) {
                  _self.subtaskErrors[element.field] = element.message;
                }
              } else {
                for (const element of data.errors) {
                  _self.errors[element.field] = element.message;
                }
              }
            } else {
              _self.error = true;
              _self.message = data.message;
            }
          }
        });
    },

    storeCommentAttachment(files) {
      let _self = this;
      _self.loading = true;

      for (const file of files) {
        this.commentAttachments.push(file);
      }

      if (this.commentAttachments.length > 0) {
        this.$refs["commentfile-uploader"].closeDialog();
        _self.loading = false;
      }
    },

    deleteComment(comment) {
      this.dialogDelete = true;
      this.deleteCommentId = comment;
    },
    deleteItemConfirm() {
      let _self = this;
      _self.$axios
        .delete(
          `delete_comment/${this.deleteCommentId.task_id}?commentId=${this.deleteCommentId.id}&project_id=${this.project_id}`
        )
        .then((response) => {
          let data = response.data;
          if (data.success) {
            this.dialogDelete = false;
          }
        });
    },
    closeDelete() {
      this.deleteCommentId = "";
      this.dialogDelete = false;
    },
    setAttachmentDialog() {
      this.attachment = [];
      this.attachmentDialog = true;
    },
    setCommentAttachmentDialog() {
      this.commentAttachments = [];
      this.commentAttachmentDialog = true;
    },
    closeAttachmentDialog() {
      this.attachment = [];
      this.attachmentDialog = false;
    },
    setSubtaskAttachmentDialog() {
      this.subtaskattachment = [];
      this.subtaskAttachmentDialog = true;
    },
    closeSubtaskAttachmentDialog() {
      this.subtaskattachment = [];
      this.subtaskAttachmentDialog = false;
    },
    filterMember(task, fromTaskList = false) {
      this.selectedTaskDetails = task;
      this.selectedTask = task.task_id;
      this.internalAssigneeSearch = "";

      if (fromTaskList) {
        this.filteredMembers = this.members.filter((member) => {
          return !task.assignees.find((assignee) => {
            return assignee.employee.id == member.employee.id;
          });
        });
      } else {
        this.filteredMembers = this.members.filter((member) => {
          return !task.displayAssignees.find((assignee) => {
            return assignee.employee.id == member.employee.id;
          });
        });
      }
    },
    filterSubTaskMember(subTask, fromTaskList = false) {
      this.subTaskAssigneeSearch = "";
      this.selectedSubTask = subTask.task_id;
      if (fromTaskList) {
        this.filterSubTaskMembers = this.members.filter((member) => {
          return !subTask.assignees.find((assignee) => {
            return assignee.employee.id == member.employee.id;
          });
        });
      }
    },
    debouncedSubTaskAssignee: debounce(function (members) {
      const arrayColumn = (arr, n) => arr.map((x) => x[n]);
      let addAssigneelist = arrayColumn(members.assignees, "employee_id");
      this.filterSubTaskMembers = this.members.filter((item) => {
        let empName = item.employee.name.toLocaleLowerCase();
        if (
          empName?.includes(this.subTaskAssigneeSearch?.toLocaleLowerCase()) &&
          !addAssigneelist.includes(item.employee_id)
        ) {
          return item;
        }
      });
    }, 500),
    saveSubTaskAssignee(employee_id, subtask_id) {
      let _self = this;

      _self.$axios
        .post("tasks/" + _self.selectedSubTask + "/assignee", {
          employee_id: employee_id,
          project_id: this.project_id,
        })
        .then((response) => {
          let data = response.data;
          _self.loading = false;

          if (data.success) {
            let subtaskIndex = this.subtasks.findIndex(
              (subtask) => subtask.task_id == subtask_id
            );
            if (subtaskIndex != -1) {
              if (
                this.subtasks[subtaskIndex] &&
                this.subtasks[subtaskIndex].assignees
              ) {
                this.subtasks[subtaskIndex].assignees = data.task?.assignees;
              }
            }
          } else {
            _self.error = true;
            _self.message = data.message;
          }
        });
    },
    removeSubTaskAssignee(subtask_id, employee_id) {
      let _self = this;
      _self.$axios
        .post("tasks/" + subtask_id + "/assignee/remove", {
          employee_id: employee_id,
          project_id: this.project_id,
        })
        .then((response) => {
          let data = response.data;
          _self.loading = false;

          if (data.success) {
            let subtaskIndex = this.subtasks.findIndex(
              (subtask) => subtask.task_id == subtask_id
            );
            if (subtaskIndex != -1) {
              if (
                this.subtasks[subtaskIndex] &&
                this.subtasks[subtaskIndex].assignees
              ) {
                this.subtasks[subtaskIndex].assignees = this.subtasks[
                  subtaskIndex
                ].assignees.filter(
                  (assignee) => assignee.employee.id != employee_id
                );
              }
            }
          } else {
            _self.error = true;
            _self.message = data.message;
          }
        });
    },
    subfilterMember() {
      this.subAssigneeSearch = "";
      this.subTaskFilteredMembers = this.members.filter((member) => {
        return !this.subtaskEditedItem.assignees.find((assignee) => {
          return assignee == member.employee.id;
        });
      });
    },
    debouncedSubAssignee: debounce(function (members) {
      // const arrayColumn = (arr, n) => arr.map((x) => x[n]);
      let addAssigneelist = members.assignees;
      this.subTaskFilteredMembers = this.members.filter((item) => {
        let empName = item.employee.name.toLocaleLowerCase();
        if (
          empName?.includes(this.subAssigneeSearch?.toLocaleLowerCase()) &&
          !addAssigneelist.includes(item.employee_id)
        ) {
          return item;
        }
      });
    }, 500),
    saveSubAssignee(empId) {
      this.subtaskEditedItem.assignees.push(empId);
      if (empId) {
        this.subtaskEditedItem.displayAssignees.push(
          this.members.find((assignee) => {
            return empId == assignee.employee.id;
          })
        );
      }
    },
    removesubAssignee(assignId) {
      this.subtaskEditedItem.displayAssignees =
        this.subtaskEditedItem.displayAssignees.filter((member) => {
          return assignId != member.employee.id;
        });
      this.subtaskEditedItem.assignees =
        this.subtaskEditedItem.assignees.filter((member) => {
          return assignId != member;
        });
    },
    saveAssignee(employee_id, fromDetails = false, isSubTask = false) {
      let _self = this;

      _self.$axios
        .post("tasks/" + _self.selectedTask + "/assignee", {
          employee_id: employee_id,
          project_id: this.project_id,
        })
        .then((response) => {
          let data = response.data;
          _self.loading = false;

          if (data.success) {
            if (fromDetails) {
              if (isSubTask) {
                this.subtaskEditedItem.list_id = data.task.list_id;
                this.subtaskEditedItem.task_id = data.task.task_id;
                this.subtaskEditedItem.name = data.task.name;
                this.subtaskEditedItem.description = data.task.description;
                this.subtaskEditedItem.assignees = data.task.assignees.map(
                  (assignee) => {
                    return assignee.employee.id;
                  }
                );
                Vue.set(
                  this.subtaskEditedItem,
                  "displayAssignees",
                  data.task.assignees
                );
                this.subtaskEditedItem.displayAssignees = data.task.assignees;
                this.subtaskEditedItem.status = data.task.status;

                // updating the subtask details
                let subtask = this.subtasks.find(
                  (subtask) => subtask.task_id == data.task.task_id
                );
                if (subtask) {
                  subtask.assignees = data.task.assignees;
                  subtask.displayAssignees = data.task.displayAssignees;
                }
              } else {
                this.editedItem.list_id = data.task.list_id;
                this.editedItem.task_id = data.task.task_id;
                this.editedItem.name = data.task.name;
                this.editedItem.description = data.task.description;
                this.editedItem.assignees = data.task.assignees.map(
                  (assignee) => {
                    return assignee.employee.id;
                  }
                );
                Vue.set(
                  this.editedItem,
                  "displayAssignees",
                  data.task.assignees
                );
                this.displayAssignees = data.task.assignees;
                this.editedItem.status = data.task.status;

                let task = this.blocks.find(
                  (task) => task.task_id == data.task.task_id
                );
                task.displayAssignees = task.assignees;
                if (task) Object.assign(task, data.task);
              }
            } else {
              let index = this.blocks.indexOf(this.selectedTaskDetails);
              Object.assign(this.blocks[index], data.task);
            }
          } else {
            _self.error = true;
            _self.message = data.message;
          }
        });
    },
    assignSubTaskAssignee(data) {
      this.subtaskEditedItem.list_id = data.task.list_id;
      this.subtaskEditedItem.task_id = data.task.task_id;
      this.subtaskEditedItem.name = data.task.name;
      this.subtaskEditedItem.description = data.task.description;
      this.subtaskEditedItem.assignees = data.task.assignees.map((assignee) => {
        return assignee.employee.id;
      });
      Vue.set(this.subtaskEditedItem, "displayAssignees", data.task.assignees);
      this.subtaskDisplayAssignees = data.task.assignees;
      this.subtaskEditedItem.status = data.task.status;

      // updating the subtask details
      let subtask = this.subtasks.find(
        (subtask) => subtask.task_id == data.task.task_id
      );
      if (subtask) {
        subtask.assignees = data.task.assignees;
        subtask.displayAssignees = data.task.displayAssignees;
      }
    },
    assignMainTaskAssignee(data) {
      this.editedItem.list_id = data.task.list_id;
      this.editedItem.task_id = data.task.task_id;
      this.editedItem.name = data.task.name;
      this.editedItem.description = data.task.description;
      this.editedItem.assignees = data.task.assignees.map((assignee) => {
        return assignee.employee.id;
      });
      Vue.set(this.editedItem, "displayAssignees", data.task.assignees);
      this.displayAssignees = data.task.assignees;
      this.editedItem.status = data.task.status;

      let task = this.blocks.find((task) => task.task_id == data.task.task_id);
      task.displayAssignees = task.assignees;
      if (task) Object.assign(task, data.task);
    },
    removeAssignee(
      task_id,
      employee_id,
      fromDetails = false,
      isSubTask = false
    ) {
      let _self = this;
      _self.$axios
        .post("tasks/" + task_id + "/assignee/remove", {
          employee_id: employee_id,
          project_id: this.project_id,
        })
        .then((response) => {
          let data = response.data;
          _self.loading = false;

          if (data.success) {
            if (fromDetails) {
              if (isSubTask) {
                this.assignSubTaskAssignee(data);
              } else {
                this.assignMainTaskAssignee(data);
              }
            } else {
              let index = this.blocks.findIndex(
                (task) => task.task_id == task_id
              );
              if (index != -1) {
                Object.assign(this.blocks[index], data.task);
              }
            }
          } else {
            _self.error = true;
            _self.message = data.message;
          }
        });
    },
    defineSourceUrl(attachment) {
      let url = attachment.url;
      if (!url.startsWith("http")) {
        url = this.API_BASE_URL + "task/attachments/" + url;
      } else {
        if (["pdf"].includes(attachment.filetype)) {
          url = url + "?view=open";
        }
      }

      return url;
    },
    closeIframe() {
      this.iframeDialog = false;
      this.subtaskAttachmentIndex = -1;
      this.attachmentIndex = -1;
    },
    getParsedComment(rawComment) {
      try {
        var urlRegex = /(https?:\/\/[^\s]+)/g;
        return rawComment.replace(urlRegex, function (url) {
          return '<a target="_blank" href="' + url + '">' + url + "</a>";
        });
        // return JSON.parse(rawComment);
      } catch (error) {
        return rawComment;
      }
    },
    handleSocketAddAssignee(data) {
      if (this.editedItem.task_id == data.task_id) {
        let assignees = [];
        assignees = data.assignees ? data.assignees : [];
        const arrayColumn = (arr, n) => arr.map((x) => x[n]);

        // data.assignees=assignees
        this.editedItem.assignees = arrayColumn(assignees, "employee_id");
        this.editedItem.displayAssignees = assignees;
      }
    },
    handleSocketAddPriority(data) {
      // this.editedItem.task_priority = data.task_priority
      if (this.editedItem.task_id == data.task_id) {
        this.setPriority = data.taskpriority;
        this.editedItem.status = data.status;
        this.editedItem.name = data.name;
        this.editedItem.description = data.description;
      }
    },

    handleSocketAddComment(data) {
      if (this.editedItem.task_id == data.task_id) {
        this.comments.push(data);
      }
    },
    handleSocketUpdateComment(data) {
      if (this.editedItem.task_id == data.task_id) {
        this.comments = this.comments.map((item) => {
          if (item.id == data.id) {
            item = data;
          }
          return item;
        });
      }
    },
    handleSocketAddsubTask(data) {
      if (this.editedItem.task_id == data.parent) {
        this.subtasks.push(data);
      }
    },
    handleSocketAddAttachment(data) {
      if (this.editedItem.task_id == data.task_id) {
        for (let i = 0; i < data.length; i++) {
          const attachment = data[i];
          this.attachments.push(attachment);
        }
      }
    },
    handleSocketRemoveAttachment(data) {
      if (this.editedItem.task_id == data.task_id) {
        this.attachments = data;
      }
    },
    handleSocketUserOnline(data) {
      this.comments = this.comments.map((item) => {
        if (item.employee_id == data[0].id) {
          item.employee = data[0];
          return item;
        }
        return item;
      });
    },
    handleSocketRemoveComment(commentId) {
      this.comments = this.comments.filter((item) => {
        if (item.id != Number(commentId)) {
          return item;
        }
      });
    },
    fetchTaskActivityLogs() {
      if (!this.editedItem || !this.editedItem.task_id) {
        console.error(
          "ERROR: Required information not found to fetch the task activity logs"
        );
      }

      const requestStartTime = Date.now();

      this.isLoadingActivityLogs = true;

      const { page, rowsPerPage } = this.activityLogsPagination;

      this.$axios
        .get(
          `tasks/${this.editedItem.task_id}/activity?page=${page}&rowsPerPage=${rowsPerPage}`
        )
        .then((response) => {
          let resData = response.data;

          if (resData.success) {
            if (Array.isArray(resData.data)) {
              this.activityLogs = this.activityLogs.concat(resData.data);
            }
            this.activityLogsPagination = {
              ...this.activityLogsPagination,
              totalPages: resData.totalPages,
              totalItems: resData.totalItems,
            };
          } else {
            this.error = true;
            this.message = "Activity Logs Cannot be Fetched";
          }
        })
        .finally(() => {
          const requestEndTime = Date.now();
          const totalTimeElapsed = requestEndTime - requestStartTime;

          // forcing loader to display for atleast 300 miliseconds
          if (totalTimeElapsed < 300) {
            setTimeout(() => {
              this.isLoadingActivityLogs = false;
            }, 300);
          } else {
            this.isLoadingActivityLogs = false;
          }
        });
    },
    loadMoreActivities() {
      this.activityLogsPagination = {
        ...this.activityLogsPagination,
        page: this.activityLogsPagination.page + 1,
      };

      this.fetchTaskActivityLogs();
    },
  },
  mounted() {
    this.$ws.$on("add_assignee", this.handleSocketAddAssignee);
    this.$ws.$on("update_task", this.handleSocketAddPriority);
    // this.$ws.$on('update_subtask', this.handleSocketAddsubTask)
    this.$ws.$on("add_priority", this.handleSocketAddPriority);
    this.$ws.$on("add_comment", this.handleSocketAddComment);
    this.$ws.$on("update_comment", this.handleSocketUpdateComment);
    this.$ws.$on("change_status", this.handleSocketAddAssignee);
    this.$ws.$on("add_subtask", this.handleSocketAddsubTask);
    this.$ws.$on("add_attachment", this.handleSocketAddAttachment);
    this.$ws.$on("remove_attachment", this.handleSocketRemoveAttachment);
    this.$ws.$on("user_online", this.handleSocketUserOnline);
    this.$ws.$on("remove_comment", this.handleSocketRemoveComment);
    this.listType = localStorage.getItem("listType");
    let localUser = localStorage.getItem("user");
    this.user = JSON.parse(localUser);
    this.setTaskDetails(this.blockTaskId);
    this.getProjectGroupList();
    this.getUser();
    this.getProjectTags();
    this.getTaskTags();
    // eventHub.$on('open-editor', (data, resolve) => { this.open(data, resolve) })
  },
};
</script>

<style lang="css">
@import "../../assets/kanban.css";

.h-80 {
  height: 75vh !important;
  justify-content: center;
}

.v-text-field--full-width.v-input--dense:not(
    .v-text-field--solo
  ).v-text-field--outlined
  .v-input__prepend-outer,
.v-text-field--full-width.v-input--dense:not(
    .v-text-field--solo
  ).v-text-field--outlined
  .v-input__prepend-inner,
.v-text-field--full-width.v-input--dense:not(
    .v-text-field--solo
  ).v-text-field--outlined
  .v-input__append-inner,
.v-text-field--full-width.v-input--dense:not(
    .v-text-field--solo
  ).v-text-field--outlined
  .v-input__append-outer,
.v-text-field--enclosed.v-input--dense:not(
    .v-text-field--solo
  ).v-text-field--outlined
  .v-input__prepend-outer,
.v-text-field--enclosed.v-input--dense:not(
    .v-text-field--solo
  ).v-text-field--outlined
  .v-input__prepend-inner,
.v-text-field--enclosed.v-input--dense:not(
    .v-text-field--solo
  ).v-text-field--outlined
  .v-input__append-inner,
.v-text-field--enclosed.v-input--dense:not(
    .v-text-field--solo
  ).v-text-field--outlined
  .v-input__append-outer {
  align-items: center;
  /* padding-left: 4px; */
  margin-top: 6px;
}

.h-auto {
  height: 75vh;
  width: 75vh;
}

.asssignee-container {
  position: relative !important;
  display: inline;
}

.asssignee-container:hover > .remove-icon {
  display: inherit !important;
}

.asssignee-container:hover > .remove-icon-in-dialog {
  display: inherit !important;
}

.v-application p {
  margin-bottom: 0px;
}

.remove-icon {
  border: 2px solid white !important;
  color: white;
  z-index: 10;
  line-height: 1 !important;
  position: absolute !important;
  top: -6px !important;
  left: 15px !important;
  display: none !important;
  vertical-align: top !important;
}

.remove-icon-in-dialog {
  padding: 2px;
  color: white;
  z-index: 10;
  position: absolute !important;
  top: -15px !important;
  left: 25px !important;
  display: none !important;
  background-color: red;
}

.drag-column {
  max-height: unset;
}

.comment > .body > p {
  margin-bottom: 0 !important;
}

.v-dialog {
  overflow-y: hidden;
}

.comment--message .mention {
  color: #0051b5;
  font-weight: 500;
}
</style>

<style scoped>
.activity_block {
  padding: 5px 15px;
  display: inline-block;
  border-radius: 5px;
}

.employeeName {
  font-size: 16px;
  font-weight: 600;
  padding-left: 5px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}
.span_text {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  line-height: 12px;
  text-align: left;
}
.v-expansion-panels:not(.v-expansion-panels--accordion):not(
    .v-expansion-panels--tile
  )
  > .v-expansion-panel--active[data-v-0f3cb0aa] {
  border-radius: 4px;
  background-color: #fafbfc !important;
}
</style>
