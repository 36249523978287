import appConfig from "./appConfig";
let config = {
    selector: 'textarea',
 
    schema: "html5",
 
    plugins: [
        "advlist autolink autosave autoresize link image lists charmap hr anchor",
        "searchreplace wordcount visualblocks visualchars code fullscreen insertdatetime nonbreaking",
        "table template paste importcss textpattern"
    ],
 
    menubar: false
};
let editorType = {
    full: {
        toolbar: [
            'undo redo | bold italic underline strikethrough | alignleft aligncenter alignright  | blockquote | formatselect',
            'cut copy paste removeformat | searchreplace | bullist numlist | outdent indent | hr | link unlink anchor image code | inserttime',
            'table | subscript superscript | charmap | visualchars visualblocks nonbreaking | template | helloworld'
        ]
    },
    simple: {
        toolbar: [
            'undo redo | bold italic underline strikethrough | formatselect | alignleft aligncenter alignright | blockquote visualblocks | bullist numlist',
        ],
    }
};
let tinyConfig = {
    getAPIKey: function () {
        return appConfig.get('tiny.key');
    },
    getConfig: function (key = 'simple') {
        // if (!key || !editorType.getOwnPropertyDescriptor(key))
        // {
        //     // default to full if there is a key, but it doesn't exist
        //     key = 'full';
        // }
        return Object.assign({}, config, editorType[key]);
    }
};

export default tinyConfig;